import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  TabContent, CardHeader, TabPane,  NavLink,  NavItem,  Nav, Alert,  FormGroup, Label, Button} from "reactstrap";
import classnames from "classnames";
import CONFIG from '../../CONFIG.json';
import { withRouter } from 'react-router-dom';
import Select from "react-select";
import { showLoader, getLoggedInUser, isEmpty, htmlToText } from '../../auxiliares/funciones';
import { secondsToTime } from '../../auxiliares/dates';
import RTabla from '../../auxiliares/tabla';
import Evaluaciones from './evaluaciones';

import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

class Grupos extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Informes", link : "/informes" }
          ],
            visible:'1',
            visibleD:false,
            seleccionado: this.props.match.params.id ? this.props.match.params.id : 0,
            info:[],
            calificaciones:[],
            seguimiento:[],
            error:'',
            error_dlg:false,
            msg:'',
            msg_dlg:false,
            grupo:'',
            grupos:[],
            examenes: [],
            listado:{
                      nombre:'',
                      lista:[],
                      buscable:true,
                      campos:[],
                      cabecera:[],
                      paginable:true,
                      scroll:true,
                      loader:true
                    }
        }
    }

    componentDidMount(){
        showLoader(true);
        this.props.setBreadcrumbItems('Informes', this.state.breadcrumbItems);
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Grupos?action=obtieneGruposAsociados&token=" + ses.token)
          .then(res3 => res3.json())
          .then((result3) => {
            showLoader(false);
            if (result3.Error === undefined) {
              const grupos = result3.map(r => {return {label: r.grupo+' - '+r.curso, value: r.grupoidreq}})
              this.setState({grupos: grupos})
              if(this.state.seleccionado !== 0){
                this.buscaInfo(this.state.seleccionado)
              }else{
                showLoader(false);
              }
            }else if (result3.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              this.setState({error:result3.Error ,error_dlg:true})
            }
        })
    }

    toggleTab(tab) {
      if (this.state.visible !== tab) {
        this.setState({visible: tab, visibleD:false})
      }
    }

    buscaInfo = (id) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Informes?action=obtieneInformeGrupo&token=" + ses.token+"&id="+id)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            this.setState({info: result3, listado:{lista:[]},visible:'1', visibleD:false, examenes:[]})
            showLoader(false);
          }else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    verConexionesDetalle = (id,id2) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Informes?action=verConexionesDetalle&grupo=" + id2+"&usuarioid="+id+"&token="+ses.token)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            if(isEmpty(result3)){
              this.setState({msg:'No hay registros para mostrar' ,msg_dlg:true, listado:{lista:[]}})
            }else{
              this.setState({msg_dlg:false})
              const lista = result3.map((r)=>{
                return {inicio:r.inicio, duracion: secondsToTime(r.tiempo), acceso: r.acceso}
              })
              const listado = {
                        nombre:'Detalle de sesiones realizadas',
                        lista:lista,
                        campos:['inicio','duracion'],
                        cabecera:['Acceso','Duración de la sesión'],
                        paginable:true,
                        scroll:true,
                        loader:true
                      }
              this.setState({listado: listado})
            }
          }else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    ampliaTiempo = (id,id2) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Informes?action=verTrazabilidad&grupo=" + id2+"&usuarioid="+id+"&token="+ses.token)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            if(isEmpty(result3)){
              this.setState({msg:'No hay registros para mostrar' ,msg_dlg:true, listado:{lista:[]}})
            }else{
              this.setState({msg_dlg:false})
              const listado = {
                        nombre:'Detalle de conexiones',
                        lista:result3,
                        campos:['descripcion','fechacreado','fechasalida'],
                        cabecera:['Acción','Desde','Hasta'],
                        paginable:true,
                        scroll:true,
                        loader:true
                      }
              this.setState({listado: listado})
            }
          }else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    ampliaTemario = (id,id2) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Informes?action=ampliaTemario&grupo=" + id2+"&usuarioid="+id+"&token="+ses.token)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            if(isEmpty(result3)){
              this.setState({msg:'No hay registros para mostrar' ,msg_dlg:true, listado:{lista:[]}})
            }else{
              this.setState({msg_dlg:false})
              const lista = result3.map((r)=>{
                return {titulo:r.titulo, nota: r.porcentaje === '' ? r.nota : r.porcentaje, conexiones: r.conexiones}
              })
              const listado = {
                        nombre:'Detalle de temario',
                        lista:lista,
                        campos:['titulo','nota','conexiones'],
                        cabecera:['Apartado','Puntuación','Visitas'],
                        paginable:false,
                        scroll:true,
                        loader:true
                      }
              this.setState({listado: listado})
            }
          }else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    ampliaTutor = (tipo,id,id2) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Informes?action=verDetalle&grupo=" + id2+"&usuarioid="+id+"&token="+ses.token+"&tipo="+tipo)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            if(isEmpty(result3)){
              this.setState({msg:'No hay registros para mostrar' ,msg_dlg:true, listado:{lista:[]}})
            }else{
              this.setState({msg_dlg:false})
              const lista = result3.map((r)=>{
                return {usuario:r.usuario, mensaje: htmlToText(r.mensaje), fecha: r.fecha}
              })
              const listado = {
                        nombre:'Partificacion en '+tipo,
                        lista:lista,
                        campos:['usuario','mensaje','fecha'],
                        cabecera:['Usuario','Mensaje','Fecha'],
                        paginable:true,
                        scroll:true,
                        loader:true
                      }
              this.setState({listado: listado})
            }
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    ampliaExamenes = (id,id2) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Informes?action=obtieneExamenesInforme&grupo=" + id2+"&usuarioid="+id+"&token="+ses.token)
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            if(isEmpty(result3)){
              this.setState({msg:'No hay registros para mostrar' ,msg_dlg:true, listado:{lista:[]}})
            }else{
              this.setState({msg_dlg:false,examenes: result3, listado:{lista:[]}})
            }
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true, listado:{lista:[]}})
          }
      })
    }

    render() {
        const {grupos, info } = this.state
        return (
            <React.Fragment>
                    <Row style={{minHeight: '400px'}}>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Informes sobre grupos</h4>
                                </CardHeader>
                                <CardBody row="true">
                                  <Col md="8" lg="8" xl="8">
                                    <FormGroup>
                                        <Label className="control-label">Selecciona un grupo para visualizar</Label>
                                        <Select value={grupos.filter(({value}) => value === this.state.seleccionado)}
                                            name="grupoSel" onChange={(e)=>this.buscaInfo(e.value)}
                                            options={grupos} className="select2"
                                        />
                                    </FormGroup>
                                  </Col>
                                </CardBody>
                            </Card>
                                {!isEmpty(info) &&
                            <Card>
                              <CardHeader>
                                <h4 className="mb-0">Grupo {info[0].grupo} - {info[0].curso}</h4>
                              </CardHeader>
                                <CardBody>
                                    <Nav tabs justified className="nav-tabs-custom" role="tablist">
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "1"})}
                                            onClick={() => { this.toggleTab("1"); }} >
                                                <span className="d-sm-block">Alumnos y Tutor</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "2"})}
                                            onClick={() => { this.toggleTab("2"); }} >
                                                <span className="d-sm-block">Evaluacion de los alumnos</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "3"})}
                                            onClick={() => { this.toggleTab("3"); }} >
                                                <span className="d-sm-block">Tutorización</span>
                                          </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={this.state.visible}>
                                        <TabPane tabId="1" className="p-3 row">
                                            {this.state.error_dlg &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                                </Col>
                                            }
                                            <Col md="12" lg="12" xl="12" className="mt-4">
                                              <div className="table-responsive mt-4">
                                                <Table className="table table-hover mb-0 table-bordered vAlignMiddle">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>Alumno</th>
                                                            <th>DNI</th>
                                                            <th>Email</th>
                                                            <th style={{textAlign: 'center'}}>Guía didáctica</th>
                                                            <th style={{textAlign: 'center'}}>Cuestionario sobre el curso</th>
                                                            <th style={{textAlign: 'center'}}>Diploma descargado</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                      {info.map((g,id)=>{
                                                          return <tr key={id}>
                                                                        <td>{g.alumno}</td>
                                                                        <td>{g.dnialumno}</td>
                                                                        <td>{g.alumnomail}</td>
                                                                        <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{window.open(CONFIG.api + 'GuiasDidacticas?action=ver&id='+g.guiaidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ver guia</Button></td>
                                                                        <td style={{textAlign: 'center'}}>{g.cuesidreq !== '' && <Button type="button" onClick={()=>{window.open(CONFIG.api + 'Cuestionario?action=ver&id='+g.cuesidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ver cuestionario</Button>}</td>
                                                                        <td style={{textAlign: 'center'}}>{g.diploma}</td>
                                                                    </tr>
                                                      })}
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </Col>
                                              <Col md="12" lg="12" xl="12" className="mt-4">
                                                <div className="table-responsive mt-4">
                                                  <Table className="table table-hover mb-0 table-bordered vAlignMiddle">
                                                      <thead className="thead-light">
                                                          <tr>
                                                              <th>Tutor</th>
                                                              <th>Email</th>
                                                              <th style={{textAlign: 'center'}}>Inicio del curso</th>
                                                              <th style={{textAlign: 'center'}}>Fin del curso</th>
                                                              <th style={{textAlign: 'center'}}>Horas curso</th>
                                                              <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                            <td>{info[0].tutor}</td>
                                                            <td>{info[0].tutoremail}</td>
                                                            <td style={{textAlign: 'center'}}>{info[0].fechainicio}</td>
                                                            <td style={{textAlign: 'center'}}>{info[0].fechafin}</td>
                                                            <td style={{textAlign: 'center'}}>{info[0].horas}</td>
                                                            <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{window.open(info[0].curriculum)}} color="primary" className="mr-1 waves-effect waves-light">Ver curriculum</Button></td>
                                                        </tr>
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                </Col>
                                        </TabPane>
                                        <TabPane tabId="2" className="p-3">
                                            {this.state.error_dlg &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                                </Col>
                                            }
                                            <div className="table-responsive mt-4">
                                              <Table className="table table-hover mb-0 table-bordered vAlignMiddle">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th>Alumno</th>
                                                          <th colSpan="2">Sesiones realizadas</th>
                                                          <th colSpan="2">Tiempo de conexión</th>
                                                          <th colSpan="2">Temario visitado</th>
                                                          <th colSpan="2">Evaluaciones realizadas</th>
                                                          <th>Nota media</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {info.map((g,id)=>{
                                                        return <tr key={id}>
                                                                      <td>{g.alumno}</td>
                                                                      <td style={{textAlign: 'center'}}>{g.numconexiones}</td>
                                                                      <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.verConexionesDetalle(g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ampliar</Button></td>
                                                                      <td style={{textAlign: 'center'}}>{g.tiempoconexiones}</td>
                                                                      <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.ampliaTiempo(g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ampliar</Button></td>
                                                                      <td style={{textAlign: 'center'}}>{g.porctotal}</td>
                                                                      <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.ampliaTemario(g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ampliar</Button></td>
                                                                      <td style={{textAlign: 'center'}}>{g.exameneshechos}/{g.examenes}</td>
                                                                      <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.ampliaExamenes(g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ampliar</Button></td>
                                                                      <td style={{textAlign: 'center'}}>{g.notaponderada}</td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                        </TabPane>
                                        <TabPane tabId="3" className="p-3">
                                            {this.state.error_dlg &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                                </Col>
                                            }
                                            <div className="table-responsive mt-4">
                                            <Table className="table table-hover mb-0 table-bordered vAlignMiddle">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>Alumno</th>
                                                        <th>Participación en foros</th>
                                                        <th>Participación en chats</th>
                                                        <th>Comunicación directa con el tutor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                  {info.map((g,id)=>{
                                                      return <tr key={id}>
                                                                    <td>{g.alumno}</td>
                                                                    <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.ampliaTutor('Foro',g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ver</Button></td>
                                                                    <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.ampliaTutor('Chat',g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ver</Button></td>
                                                                    <td style={{textAlign: 'center'}}><Button type="button" onClick={()=>{this.ampliaTutor('Mensajes',g.usuidreq,g.grupoidreq)}} color="primary" className="mr-1 waves-effect waves-light">Ver</Button></td>
                                                                </tr>
                                                  })}
                                                </tbody>
                                              </Table>
                                              </div>
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                            }
                            {!isEmpty(this.state.listado.lista) ?
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">{this.state.listado.nombre}</h4>
                                </CardHeader>
                                <CardBody row="true">
                                  <RTabla key={this.state.listado.nombre} lista={this.state.listado.lista} buscable={this.state.listado.buscable}
                                  campos={this.state.listado.campos} cabecera={this.state.listado.cabecera}
                                  paginable={this.state.listado.paginable} scroll={this.state.listado.scroll} loader={this.state.listado.loader}/>
                                </CardBody>
                            </Card>
                            : !isEmpty(this.state.examenes) &&
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Detalle de evaluaciones</h4>
                                </CardHeader>
                                <CardBody row="true">
                                    <Evaluaciones examenes={this.state.examenes} />
                                </CardBody>
                            </Card>
                            }
                            {this.state.msg_dlg &&
                                <Col md="12" lg="12" xl="12">
                                  <Alert color="warning"><i className="dripicons-warning"></i> {this.state.msg}</Alert>
                                </Col>
                            }
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Grupos));
