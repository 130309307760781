import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Alert,Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logodark from "../assets/images/logo-dark.png";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { showLoader } from '../auxiliares/funciones';
import CONFIG from '../CONFIG.json';

class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
          username: "",
          success: false,
          error:'',
          error_dlg:false
         }
    }

    recuperarContrasena = (event, values) => {
        this.setState({success:false ,error_dlg:false})
        showLoader(true);
        fetch(CONFIG.api + "Sesion?action=reseteaContrasena&usuario=" + values.usuario+"&email="+values.email)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              this.setState({success:true})
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname} 
              });
            }else{
              this.setState({error:result.Error ,error_dlg:true})
            }
            showLoader(false);
          })
    }

    componentDidMount(){
        showLoader(false);
    }

    render() {

        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="\" className="logo logo-admin"><img className="originalSize" src={logodark} alt="logo" /></Link>
                                        </h3>
                                        <div className="p-3">
                                            <h4 className="text-muted font-size-18 mb-3 text-center">Recuperar contraseña</h4>
                                            {this.state.success ? <Alert color="success">Se ha enviado un mail a tu correo con tu nueva contraseña.</Alert>
                                            : <Alert color="info">Introduce tu usuario y tu email y te mandaremos a tu correo las instrucciones.</Alert>}
                                            {this.state.error_dlg && <Alert color="danger">{this.state.error}</Alert>}

                                            <AvForm className="form-horizontal mt-4" onValidSubmit={this.recuperarContrasena}>

                                                <div className="form-group">
                                                    <label htmlFor="useremail">Usuario</label>
                                                    <AvField name="usuario" type="text" id="usuario" placeholder="Introduce tu usuario" validate={{required: true}} />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="useremail">Email</label>
                                                    <AvField name="email" type="email" id="email" placeholder="Introduce tu email" validate={{email:true, required: true}} />
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-12 text-right">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Recuperar</Button>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-0 row">
                                                    <Col xs="12" className="mt-4">
                                                        <Link to="/login" className="text-muted"><i className="dripicons-user"></i> Acceder</Link>
                                                    </Col>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { message, loginError, loading } = state.Forget;
    return { message, loginError, loading };
}

export default withRouter(connect(mapStatetoProps, {})(ForgetPassword));
