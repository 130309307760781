import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { showLoader, getProfile } from '../auxiliares/funciones';
import { authProtectedRoutes } from "../routes/";
import { setBreadcrumbItems } from "../auxiliares/breadcrumb/actions";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Inicio", link : "/app" }
            ]
        }
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Inicio", this.state.breadcrumbItems);
        showLoader(false)
    }

    render() {
      const profileID = getProfile()
      const option = authProtectedRoutes.filter(route => route.defaultProfile && route.defaultProfile.includes(profileID))
      if(option.length !== 0){
        return <Redirect to={{ pathname: option[0].path }} />
      }else{
      //TODO poner que redirija a la defaultProfile
        return (
            <React.Fragment>

            </React.Fragment>
        );
      }
    }
}

export default connect(null, { setBreadcrumbItems })(Dashboard);
