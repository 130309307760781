import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  CardHeader, Alert,  FormGroup, Label, Input} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import { showLoader, getLoggedInUser, isEmpty } from '../../auxiliares/funciones';

import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

class Grupos extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Grupos", link : "/grupos" }
          ],
            visible:'1',
            grupos:[],
            gruposO:[],
            error:'',
            error_dlg:false
        }
    }

    componentDidMount(){
        showLoader(true);
        this.props.setBreadcrumbItems('Grupos', this.state.breadcrumbItems);
        const ses = getLoggedInUser()
        this.obtieneGruposAsociados(ses.token)
    }

    buscaTabla = (input) =>{
        const datos = this.state.gruposO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({grupos: datos})
    }

    obtieneGruposAsociados = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtieneGruposAsociados&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            this.setState({grupos: result3, gruposO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
          showLoader(false);
      })
    }


    render() {
        const {grupos} = this.state
        return (
            <React.Fragment>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Grupos formativos</h4>
                                </CardHeader>
                                <CardBody>
                                    <h4 className="card-title">Grupos formativos</h4>
                                    {this.state.error_dlg &&
                                        <Col md="12" lg="12" xl="12">
                                          <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                        </Col>
                                    }
                                    { isEmpty(this.state.gruposO)
                                      ? <Alert color="info"><i className="dripicons-information"></i> No tienes ningun grupo activo asignado.</Alert>
                                      :
                                    <div className="table-responsive mt-4">
                                      <FormGroup>
                                          <Label className="control-label">Buscar</Label>
                                          <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e)} type="text" name="buscar"/>
                                      </FormGroup>
                                      <Table className="table table-hover mb-0 table-bordered vAlignMiddle">
                                          <thead className="thead-light">
                                              <tr>
                                                  <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                  <th>Curso</th>
                                                  <th>Grupo formativo</th>
                                                  <th>Alumno</th>
                                                  <th>Fecha inicio</th>
                                                  <th>Fecha fin</th>
                                                  {/*}<th style={{textAlign: 'center'}}><i className="dripicons-document"></i></th>*/}
                                              </tr>
                                          </thead>
                                          <tbody>
                                            {grupos.map((g,id)=>{
                                                return <tr key={id}>
                                                              <td><Link to={'/curso/'+g.cursoid} className="mr-1 btn btn-primary waves-effect waves-light">Ir al curso</Link></td>
                                                              <td>{g.curso}</td>
                                                              <td>{g.grupo}</td>
                                                              <td>{g.alumno}</td>
                                                              <td>{g.fechainicio}</td>
                                                              <td>{g.fechafin}</td>
                                                              {/*}<td><Link to={'/informe/'+g.grupoidreq} className="mr-1 btn btn-primary waves-effect waves-light">Ver informe</Link></td>*/}
                                                          </tr>
                                            })}
                                          </tbody>
                                        </Table>
                                      </div>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Grupos));
