import React, { Component } from 'react';
import {  Card,   CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getEvents, getCourseInfo } from '../../auxiliares/funciones';
import { getToday } from '../../auxiliares/dates';

class Eventos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventos:getEvents(),
            curso : getCourseInfo()
        }
    }

    render() {
      const curso = this.state.curso
        return (
            <React.Fragment>
                    { this.state.eventos.length > 0 && this.state.eventos[0] !== '' && this.state.eventos.map((e,id) =>{
                      return <Card className="mini-stat bg-warning" key={id} style={{cursor : "pointer"}}>
                                {getToday('string') === curso.fechasChatDate ?
                                <Link to={'/curso/'+this.props.grupoid+'/chat/'+this.state.curso.chatid}>
                                  <CardBody className="mini-stat-img">
                                      <div className="mini-stat-icon">
                                          <i className="dripicons-alarm float-right" style={{paddingTop:'5px'}}></i>
                                      </div>
                                      <div className="text-white">
                                          <h6 className="text-uppercase mb-3 font-size-16">Próximo evento</h6>
                                          <h2 className="mb-4">Chat</h2>
                                          <span className="ml-2">{e}</span>
                                      </div>
                                  </CardBody>
                                </Link>
                                 :<Link to={'#'} onClick={()=>{this.props.muestraMensajeChat()}}>
                                   <CardBody className="mini-stat-img">
                                       <div className="mini-stat-icon">
                                           <i className="dripicons-alarm float-right" style={{paddingTop:'5px'}}></i>
                                       </div>
                                       <div className="text-white">
                                           <h6 className="text-uppercase mb-3 font-size-16">Próximo evento</h6>
                                           <h2 className="mb-4">Chat</h2>
                                           <span className="ml-2">{e}</span>
                                       </div>
                                   </CardBody>
                                 </Link>}
                            </Card>
                      })}
                </React.Fragment>
        );
    }
}

export default connect(null, { })(Eventos);
