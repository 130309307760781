import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { showLoader } from '../auxiliares/funciones';

class LogoutButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialog: false
        }
    }

    closeSession = () => {
        this.setState({dialog:false})
        showLoader(true)
        this.props.history.push('/logout');
    }

    render() {
        return (
            <React.Fragment>
                    <div className="dropdown d-inline-block">
                        <button type="button" onClick={ () =>{this.setState({dialog:true})}} className="btn header-item noti-icon right-bar-toggle waves-effect">
                            <i className={"dripicons-exit"}></i>
                            <div className="info">Cerrar sesión</div>
                        </button>
                    </div>
                        { this.state.dialog &&
                          <SweetAlert
                          title="¿Estás seguro de cerrar sesión?"
                          warning
                          showCancel
                          confirmBtnText="Aceptar"
                          cancelBtnText="Cancelar"
                          confirmBtnBsStyle="primary"
                          cancelBtnBsStyle="secondary"
                          onConfirm={() => this.closeSession()}
                          onCancel={()=>{this.setState({dialog:false})}}
                          />
                        }
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { show_rightsidebar } = state.Layout;
    return { show_rightsidebar };
}

export default withRouter(connect(mapStatetoProps, { })(LogoutButton));
