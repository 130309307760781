import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  TabContent,  TabPane,  NavLink,  NavItem,  Nav, Alert,  FormGroup, Label, Input, CardHeader} from "reactstrap";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import { showLoader, getLoggedInUser, isEmpty } from '../../auxiliares/funciones';

import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

class Grupos extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Grupos", link : "/grupos" }
          ],
            visible:'1',
            grupos:[],
            eventos:[],
            pasados:[],
            actividades:[],
            mensajes:[],
            foros:[],
            gruposO:[],
            eventosO:[],
            pasadosO:[],
            actividadesO:[],
            mensajesO:[],
            forosO:[],
            error:[],
            error_dlg:[false,false,false,false,false,false]
        }
    }

    componentDidMount(){
        showLoader(true);
        this.props.setBreadcrumbItems('Grupos', this.state.breadcrumbItems);
        const ses = getLoggedInUser()
        this.obtieneGruposAsociados(ses.token)
        this.obtieneProximosEventos(ses.token)
        this.obtienePasadosEventos(ses.token)
        this.obtieneActividadesNoCorregidas(ses.token)
        this.obtieneMensajesNoRespondidos(ses.token)
        this.obtieneForosNoRespondidos(ses.token)
    }

    buscaTabla = (input,tabla) =>{
      if(tabla === 'grupos'){
        const datos = this.state.gruposO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({grupos: datos})
      }else if(tabla === 'eventos'){
        const datos = this.state.eventosO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({eventos: datos})
      }else if(tabla === 'pasados'){
        const datos = this.state.pasadosO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({pasados: datos})
      }else if(tabla === 'actividades'){
        const datos = this.state.actividadesO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({actividades: datos})
      }else if(tabla === 'mensajes'){
        const datos = this.state.mensajesO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({mensajes: datos})
      }else if(tabla === 'foros'){
        const datos = this.state.forosO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({foros: datos})
      }
    }

    obtieneGruposAsociados = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtieneGruposAsociados&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            this.setState({grupos: result3, gruposO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            const {error, error_dlg} = this.state
            error[0] = result3.Error
            error_dlg[0] = true
            this.setState({error:error ,error_dlg:error_dlg})
          }
      })
    }

    obtieneProximosEventos = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtieneProximosEventos&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            this.setState({eventos: result3, eventosO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            const {error, error_dlg} = this.state
            error[1] = result3.Error
            error_dlg[1] = true
            this.setState({error:error ,error_dlg:error_dlg})
          }
      })
    }

    obtienePasadosEventos = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtienePasadosEventos&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            this.setState({pasados: result3, pasadosO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            const {error, error_dlg} = this.state
            error[2] = result3.Error
            error_dlg[2] = true
            this.setState({error:error ,error_dlg:error_dlg})
          }
      })
    }

    obtieneActividadesNoCorregidas = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtieneActividadesNoCorregidas&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            this.setState({actividades: result3, actividadesO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            const {error, error_dlg} = this.state
            error[3] = result3.Error
            error_dlg[3] = true
            this.setState({error:error ,error_dlg:error_dlg})
          }
      })
    }

    obtieneMensajesNoRespondidos = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtieneMensajesNoRespondidos&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            this.setState({mensajes: result3, mensajesO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            const {error, error_dlg} = this.state
            error[4] = result3.Error
            error_dlg[4] = true
            this.setState({error:error ,error_dlg:error_dlg})
          }
      })
    }

    obtieneForosNoRespondidos = (ses) =>{
      fetch(CONFIG.api + "Grupos?action=obtieneForosNoRespondidos&token=" + ses)
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            this.setState({foros: result3, forosO: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            const {error, error_dlg} = this.state
            error[5] = result3.Error
            error_dlg[5] = true
            this.setState({error:error ,error_dlg:error_dlg})
          }
      })
    }

    toggleTab(tab) {
      if (this.state.visible !== tab) {
        this.setState({visible: tab})
      }
    }

    render() {
        const {grupos, eventos, pasados, actividades, mensajes, foros } = this.state
        return (
            <React.Fragment>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Grupos formativos</h4>
                                </CardHeader>
                                <CardBody>
                                    <Nav tabs justified className="nav-tabs-custom" role="tablist">
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "1"})}
                                            onClick={() => { this.toggleTab("1"); }} >
                                                <span className="d-sm-block">Grupos ({this.state.gruposO.length})</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "2"})}
                                            onClick={() => { this.toggleTab("2"); }} >
                                                <span className="d-sm-block">Próximas tareas ({this.state.eventosO.length})</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "3"})}
                                            onClick={() => { this.toggleTab("3"); }} >
                                                <span className="d-sm-block">Tareas pasadas ({this.state.pasadosO.length})</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "4"})}
                                            onClick={() => { this.toggleTab("4"); }} >
                                                <span className="d-sm-block">Actividades presentadas ({this.state.actividadesO.length})</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "5"})}
                                            onClick={() => { this.toggleTab("5"); }} >
                                                <span className="d-sm-block">Mensajes de alumnos ({this.state.mensajesO.length})</span>
                                          </NavLink>
                                        </NavItem>
                                        <NavItem>
                                          <NavLink style={{ cursor : "pointer" }} className={classnames({active: this.state.visible === "6"})}
                                            onClick={() => { this.toggleTab("6"); }} >
                                                <span className="d-sm-block">Foros desatendidos ({this.state.forosO.length})</span>
                                          </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={this.state.visible}>
                                        <TabPane tabId="1" className="p-3">
                                            {this.state.error_dlg[0] &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error[0]}</Alert>
                                                </Col>
                                            }
                                            { isEmpty(this.state.gruposO)
                                              ? <Alert color="info"><i className="dripicons-information"></i> No tienes ningun grupo activo asignado.</Alert>
                                              :
                                            <div className="table-responsive mt-4">
                                              <FormGroup>
                                                  <Label className="control-label">Buscar</Label>
                                                  <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'grupos')} type="text" name="buscar"/>
                                              </FormGroup>
                                              <Table className="table table-hover mb-0 table-bordered">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          <th>Curso</th>
                                                          <th>Grupo formativo</th>
                                                          <th>Alumno</th>
                                                          <th>Fecha inicio</th>
                                                          <th>Fecha fin</th>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-document"></i></th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {grupos.map((g,id)=>{
                                                        return <tr key={id}>
                                                                      <td><Link to={'/curso/'+g.cursoid} className="mr-1 btn btn-primary waves-effect waves-light">Ir al curso</Link></td>
                                                                      <td>{g.curso}</td>
                                                                      <td>{g.grupo}</td>
                                                                      <td>{g.alumno}</td>
                                                                      <td>{g.fechainicio}</td>
                                                                      <td>{g.fechafin}</td>
                                                                      <td><Link to={'/informe/'+g.grupoidreq} className="mr-1 btn btn-primary waves-effect waves-light">Ver informe</Link></td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="2" className="p-3">
                                            {this.state.error_dlg[1] &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error[1]}</Alert>
                                                </Col>
                                            }
                                            { isEmpty(this.state.eventosO)
                                              ? <Alert color="info"><i className="dripicons-information"></i> No tienes ninguna tarea programada.</Alert>
                                              :
                                            <div className="table-responsive mt-4">
                                              <FormGroup>
                                                  <Label className="control-label">Buscar</Label>
                                                  <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'eventos')} type="text" name="buscar"/>
                                              </FormGroup>
                                              <Table className="table table-hover mb-0 table-bordered">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          <th>Fecha tarea</th>
                                                          <th>Curso</th>
                                                          <th>Tipo tarea</th>
                                                          <th>Inicio curso</th>
                                                          <th>Fecha fin</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {eventos.map((g,id)=>{
                                                        const icon = g.tipo.includes('Chat') ? 'dripicons-conversation' : 'dripicons-broadcast'
                                                        const url = '/curso/'+g.cursoid+ (g.tipo.includes('Chat') ? (g.idreq === '0' ? '' : '/chat/'+g.idreq) : "/foros")
                                                        const enlace = g.tipo.includes('Chat') ? 'Chat' : 'Foro'

                                                        return <tr key={id}>
                                                                      <td><Link to={url} className="mr-1 btn btn-primary waves-effect waves-light"><i className={icon}></i> Ir al {enlace}</Link></td>
                                                                      <td>{g.fecha}</td>
                                                                      <td>{g.curso}</td>
                                                                      <td>{g.tipo}</td>
                                                                      <td>{g.fechainicio}</td>
                                                                      <td>{g.fechafin}</td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="3" className="p-3">
                                            {this.state.error_dlg[2] &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error[2]}</Alert>
                                                </Col>
                                            }
                                            { isEmpty(this.state.pasadosO)
                                              ? <Alert color="info"><i className="dripicons-information"></i> No tienes ningun tarea pasada.</Alert>
                                              :
                                            <div className="table-responsive mt-4">
                                              <FormGroup>
                                                  <Label className="control-label">Buscar</Label>
                                                  <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'pasados')} type="text" name="buscar"/>
                                              </FormGroup>
                                              <Table className="table table-hover mb-0 table-bordered">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          <th>Curso</th>
                                                          <th>Tipo</th>
                                                          <th>Fecha tarea</th>
                                                          <th>Fecha inicio</th>
                                                          <th>Fecha fin</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {pasados.map((g,id)=>{
                                                        return <tr key={id}>
                                                                      <td><Link to={'/curso/'+g.cursoid+"/foros"} className="mr-1 btn btn-primary waves-effect waves-light">Entrar al foro</Link></td>
                                                                      <td>{g.curso}</td>
                                                                      <td>{g.tipo}</td>
                                                                      <td>{g.fechaevento}</td>
                                                                      <td>{g.fechainicio}</td>
                                                                      <td>{g.fechafin}</td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="4" className="p-3">
                                            {this.state.error_dlg[3] &&
                                                <Col md="12" lg="12" xl="12">
                                                  <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error[3]}</Alert>
                                                </Col>
                                            }
                                            { isEmpty(this.state.actividadesO)
                                              ? <Alert color="info"><i className="dripicons-information"></i> No tienes ninguna actividad pendiente de corregir.</Alert>
                                              :
                                            <div className="table-responsive mt-4">
                                              <FormGroup>
                                                  <Label className="control-label">Buscar</Label>
                                                  <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'actividades')} type="text" name="buscar"/>
                                              </FormGroup>
                                              <Table className="table table-hover mb-0 table-bordered">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          <th>Fecha presentado</th>
                                                          <th>Curso</th>
                                                          <th>Grupo</th>
                                                          <th>Alumno</th>
                                                          <th>Fin curso</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {actividades.map((g,id)=>{
                                                        return <tr key={id}>
                                                                      <td style={{textAlign: 'center'}}><Link to={'/curso/'+g.cursoid+"/actividad/"+g.actividadid} className="mr-1 btn btn-primary waves-effect waves-light">Ir a la actividad</Link></td>
                                                                      <td>{g.fecha}</td>
                                                                      <td>{g.curso}</td>
                                                                      <td>{g.grupo}</td>
                                                                      <td>{g.usuario}</td>
                                                                      <td>{g.fechafin}</td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="5" className="p-3">
                                            {this.state.error_dlg[4] &&
                                                <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error[4]}</Alert>
                                            }
                                            { isEmpty(this.state.mensajesO)
                                              ? <Alert color="info"><i className="dripicons-information"></i> No tienes ningun mensaje de alumno.</Alert>
                                              :
                                            <div className="table-responsive mt-4">
                                              <FormGroup>
                                                  <Label className="control-label">Buscar</Label>
                                                  <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'mensajes')} type="text" name="buscar"/>
                                              </FormGroup>
                                              <Table className="table table-hover mb-0 table-bordered">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          <th>Alumno</th>
                                                          <th>Fecha Ult. Mensj.</th>
                                                          <th>Nº Mensajes</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {mensajes.map((g,id)=>{
                                                        return <tr key={id}>
                                                                      <td style={{textAlign: 'center'}}><Link to={'/mensajes/'+g.alumnoid} className="mr-1 btn btn-primary waves-effect waves-light">Ir al buzon de mensajes</Link></td>
                                                                      <td>{g.usuario}</td>
                                                                      <td>{g.fecha}</td>
                                                                      <td>{g.mensajes}</td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            }
                                        </TabPane>
                                        <TabPane tabId="6" className="p-3">
                                            {this.state.error_dlg[5] &&
                                                <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error[5]}</Alert>
                                            }
                                            { isEmpty(this.state.forosO)
                                              ? <Alert color="info"><i className="dripicons-information"></i> No tienes ningun foro desatendido.</Alert>
                                              :
                                            <div className="table-responsive mt-4">
                                              <FormGroup>
                                                  <Label className="control-label">Buscar</Label>
                                                  <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla"
                                                   onChange={(e)=>this.buscaTabla(e,'foros')} type="text" name="buscar"/>
                                              </FormGroup>
                                              <Table className="table table-hover mb-0 table-bordered">
                                                  <thead className="thead-light">
                                                      <tr>
                                                          <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                                          <th>Foro</th>
                                                          <th>Tema</th>
                                                          <th>Curso</th>
                                                          <th>Alumno</th>
                                                          <th>Fecha del post</th>
                                                          <th>Fin de curso</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                    {foros.map((g,id)=>{
                                                        return <tr key={id}>
                                                                      <td style={{textAlign: 'center'}}><Link to={'/curso/'+g.cursoid+"/foro/"+g.foroid+"/"+g.temaid} className="mr-1 btn btn-primary waves-effect waves-light">Entrar al foro</Link></td>
                                                                      <td>{g.foro}</td>
                                                                      <td>{g.tema}</td>
                                                                      <td>{g.curso}</td>
                                                                      <td>{g.alumno}</td>
                                                                      <td>{g.fecha}</td>
                                                                      <td>{g.fechafin}</td>
                                                                  </tr>
                                                    })}
                                                  </tbody>
                                                </Table>
                                              </div>
                                            }
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Grupos));
