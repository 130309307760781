export const getTimeFromSeconds = (time)=>{
    // Hours, minutes and seconds
    var dias = ~~(time / (3600*24));
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (dias > 0) {
        ret += "" + dias + " dias";
    }else if (hrs > 0) {
        ret += "" + hrs + " horas";
    }else if(mins>0){
        ret += "" + mins + " minutos";
    }else if(secs>0){
        ret += "" + secs + " segundos";
    }else{
        ret += " ahora mismo";
    }
    return ret;
}

export const stringToDate = (value) => {
  var dt = value.substring(0, 2);
  var mon = value.substring(3, 5);
  var yr = value.substring(6, 10);
  var date = new Date(yr+'-'+mon+'-'+dt);
  return date
};

export const stringToDatetime = (value) => {
  var dt = value.substring(0, 2);
  var mon = value.substring(3, 5);
  var yr = value.substring(6, 10);
  var hr = value.substring(11, 13);
  var mi = value.substring(14, 16);
  var date;
  if(value.length>16){
    var sg = value.substring(17, 19);
    date = new Date(yr+'-'+mon+'-'+dt+' '+hr+':'+mi+':'+sg);
  }else{
    date = new Date(yr+'-'+mon+'-'+dt+' '+hr+':'+mi);
  }
  return date
};

export const dateToString = (value) => {
  if(value === ''){
    return '';
  }
  var day = value.getDate() + "";
  var month = (value.getMonth() + 1) + "";
  var year = value.getFullYear() + "";
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  return day + "-" + month + "-" + year;
};

export const datetimeToString = (value) => {
  var day = value.getDate() + "";
  var month = (value.getMonth() + 1) + "";
  var year = value.getFullYear() + "";
  var hour = value.getHours() + "";
  var minutes = value.getMinutes() + "";
  var seconds = value.getSeconds() + "";
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);
  seconds = checkZero(seconds);
  return day + "-" + month + "-" + year + " " + hour + ":" + minutes + ":" + seconds;
};

export const dateToStringDate = (value) => {
  var day = value.getDate() + "";
  var month = (value.getMonth() + 1) + "";
  var year = value.getFullYear() + "";
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  return year + "-" + month + "-" + day;
};

export const datetimeToStringDatetime = (value) => {
  var day = value.getDate() + "";
  var month = (value.getMonth() + 1) + "";
  var year = value.getFullYear() + "";
  var hour = value.getHours() + "";
  var minutes = value.getMinutes() + "";
  var seconds = value.getSeconds() + "";
  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);
  seconds = checkZero(seconds);
  return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
};

export const compareDates = (first,second) =>{
  if(first.getTime() < second.getTime()){//Si el primero es menor devuelvo -1
    return -1
  }else if(first.getTime() > second.getTime()){//Si el primero es mayor devuelvo 1
    return 1
  }else{//Si son iguales devuelvo 0
    return 0
  }
}

export const secondsToTime = (time) =>{
    // Hours, minutes and seconds
    var dias = ~~(time / (3600*24));
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (dias > 0) {
        ret += "" + dias + " dias ";
    }if (hrs > 0) {
        ret += "" + hrs + " h ";
    }if(mins>0){
        ret += "" + mins + " min ";
    }if(secs>0){
        ret += "" + secs + " segs";
    }
    if(ret===''){
        ret="0 segs";
    }
    return ret;
}

export const getToday = (format) =>{
  var date=new Date()
  if(format === 'date'){
    return dateToStringDate(date)
  }else if(format === 'datetime'){
    return datetimeToStringDatetime(date)
  }else if(format === 'string'){
    return dateToString(date)
  }else if(format === 'stringtime'){
    return datetimeToString(date)
  }
}

function checkZero(data){
  if(data.length === 1){
    data = "0" + data;
  }
  return data;
}
