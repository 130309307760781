import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, CardImg, CardFooter} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CONFIG from '../../../CONFIG.json';
import Paginacion from './paginacion';
import ListContenido from './listaCont';
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty, isNumber } from '../../../auxiliares/funciones';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../auxiliares/breadcrumb/actions";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


class Extras extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "", link : ""},
          ],
          showImage: false,
          image: '',
          course : course,
          detalle: {},
          contenido: []
        }
    }

    componentDidMount(){
      this.getDetalle(this.props.match.params.id3)
    }

    getDetalle = (detalleid) =>{
      showLoader(true);
      window.scrollTo(0, 0);
      if(isNumber(detalleid) || detalleid === 'glosario'){
        this.props.history.push("/curso/"+this.props.match.params.id+"/contenido/"+this.props.match.params.id2+"/detalle/"+detalleid);
      }else{
        const ses = getLoggedInUser()
        const grupoid = getProfile() === 4 ? this.props.match.params.id : 'null'
        fetch(CONFIG.api + "Cursos?action=contenidodetallepornombre&contenidoid="+this.props.match.params.id2+"&token=" + ses.token+"&nombre="+detalleid+"&grupoid="+grupoid)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              let bread = this.state.breadcrumbItems
              bread[3].title = result.unidad
              bread[3].link = "/curso/"+this.props.match.params.id+"/contenido/"+result.contenidoid+"/"+detalleid
              this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);

              fetch(CONFIG.api + "Cursos?action=contenidocursodetalle&token=" + ses.token+"&contenidoid="+this.props.match.params.id2+"&nombre="+detalleid)
                .then(res2 => res2.json())
                .then((result2) => {
                  showLoader(false);
                  if (result2.Error === undefined) {
                    this.setState({contenido:result2 , detalle:result})
                  } else if (result2.Caducada !== undefined) {
                    this.props.history.push({
                      pathname: '/login',
                      search: '',
                      state: { caducada: true, url: this.props.location.pathname} 
                    });
                  }else{
                    this.setState({error:result2.Error ,error_dlg:true})
                  }
              })
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname} 
              });
            }else{
              showLoader(false);
              this.setState({error:result.Error ,error_dlg:true})
            }
        })
      }
    }

    muestraImagenVideo = (e) =>{
      if(e.target.tagName === 'IMG'){
        this.setState({showImage:true ,image:e.target.src})
      }
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    render() {
        const detalle = this.state.detalle
        const contenido = this.state.contenido
        return (
                  <React.Fragment>
                {this.state.showImage &&
                  <Lightbox
                    mainSrc={this.state.image}
                    enableZoom={true}
                    onCloseRequest={() => this.setState({ showImage: false })}
                  />
                }
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {!isEmpty(detalle) && !isEmpty(contenido) &&
                    <Col md="8" lg="8" xl="8">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">{detalle.unidad}</h4>
                            </CardHeader>
                            <CardBody>
                                <Paginacion
                                    getDetalle={this.getDetalle}
                                    anterior={contenido[0].anterior}
                                    siguiente={contenido[0].siguiente}
                                    grupoid={this.props.match.params.id}
                                    contenidoid={contenido[0].contenidoid}/>
                                <h4 className="card-title">{detalle.titulo}</h4>
                                {detalle.total !== '1/1' && <h6 className="card-subtitle font-14 text-muted">{detalle.total}</h6>}
                            </CardBody>
                            {(this.props.match.params.id3 === 'mapa-conceptual' && detalle.contenido !== '') &&
                            <CardImg top className="img-fluid imgPointer" src={detalle.contenido} alt="" onClick={(e) => {this.muestraImagenVideo(e)}}/>
                            }
                            {((this.props.match.params.id3 === 'objetivos' || this.props.match.params.id3 === 'bibliografia' || this.props.match.params.id3 === 'resumen')
                              && detalle.contenido !== '') &&
                              <CardBody>
                              <div onClick={(e) => {this.muestraImagenVideo(e)}} dangerouslySetInnerHTML={{ __html: detalle.contenido }}></div>
                              </CardBody>
                            }
                            <CardFooter>
                                <Paginacion
                                    getDetalle={this.getDetalle}
                                    anterior={contenido[0].anterior}
                                    siguiente={contenido[0].siguiente}
                                    grupoid={this.props.match.params.id}
                                    contenidoid={contenido[0].contenidoid}/>
                            </CardFooter>
                        </Card>
                    </Col>
                  }
                  {!isEmpty(contenido) &&
                    <Col md="4" lg="4" xl="4">
                      <ListContenido getDetalle={this.getDetalle} grupoid={this.props.match.params.id} detalleid={this.props.match.params.id3} contenido={contenido} titulo={detalle.titulo} />
                    </Col>
                  }
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Extras));
