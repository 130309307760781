import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button, Form, Modal, ModalHeader, ModalBody,  ModalFooter, FormGroup, Label } from 'reactstrap';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CONFIG from '../CONFIG.json';
import { showLoader,getLoggedInUser, isEmpty, getProfile } from '../auxiliares/funciones';

import SweetAlert from "react-bootstrap-sweetalert";
import SunEditor from 'suneditor-react';
import Select from "react-select";
import 'suneditor/dist/css/suneditor.min.css';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../auxiliares/breadcrumb/actions";


class Foro extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Soporte", link : "/soporte" }
          ],
          posts:[],
          modal: false,
          incidencia:{incidencia:'',curso:''},
          texto:'',
          error:'',
          error_dlg: false
        }
    }

    abreModal = (postid)=> {
        this.setState({modal: true, postid: postid})
    }

    cierraModal = ()=> {
        this.setState({modal: false, foroid: 0,error_dlg:false})
    }

    rellenaTexto = (texto) =>{
      this.setState({texto: texto})
    }

    actualizaRespuestasSelect = (nombre,respuesta) =>{
      let res = this.state.incidencia
      res[nombre] = respuesta
      this.setState({incidencia:res})
    }

    postearMensaje = () =>{
      if(this.state.texto === ''){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Datos incorrectos",texto:'Debes rellenar el mensaje.'}})
      }else{
        showLoader(true);
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Soporte?action=anyadirMensaje&token=" + ses.token+"&incidencia="+this.state.incidencia.incidencia+"&mensaje="+this.state.texto+"&curso="+this.state.incidencia.curso)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              window.location.reload()
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
      }
    }

    componentDidMount(){
      showLoader(true);
      const ses = getLoggedInUser()
      this.props.setBreadcrumbItems('Soporte', this.state.breadcrumbItems);
      fetch(CONFIG.api + "Soporte?action=obtieneMensajesPrevios&token=" + ses.token)
        .then(res => res.json())
        .then((result) => {
          showLoader(false);
          if (result.Error === undefined) {
            this.setState({posts:result})
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.muestraError(result.Error)
          }
      })
      if(getProfile() === 4 || getProfile() === 2){
        fetch(CONFIG.api + "Soporte?action=obtieneCursosVinculados&token=" + ses.token)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              if(result.length > 0){
              const cursos = result.map(r => {
                return { label: r.nombre, value: r.id }
              })
              this.setState({cursos:cursos})
            }else{
              this.setState({cursos:[]})
            }
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              this.muestraError(result.Error)
            }
        })
      }
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    render() {
        const ses = getLoggedInUser()
        const posts = this.state.posts
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                    <Col md="12" lg="12" xl="12">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">Conversación</h4>
                            </CardHeader>
                            {this.state.muestraMsg &&
                              <SweetAlert
                              type={this.state.msg.tipo}
                              title={this.state.msg.titulo}
                              onConfirm={() => this.setState({ muestraMsg: false })}
                              >
                              {this.state.msg.texto}
                              </SweetAlert>
                            }
                            <CardBody>
                              {/*this.state.cursos !== undefined && */}
                              <button type="button" onClick={()=>{this.abreModal('')}} className="btn btn-primary btn-rounded waves-effect waves-light m-t-5">Enviar mensaje</button>
                              <section id="cd-timeline" className="cd-container" dir="ltr">
                              {!isEmpty(posts) &&
                                posts.map((p,id)=>{
                                const position = p.respuesta === '1' ? 'timeline-left' : 'timeline-right'
                                const classChild = p.respuesta === '1' ? 'foroLeft' : 'foroRight'
                                return <div key={id} className={"cd-timeline-block "+position}>
                                        <div className={"cd-timeline-content width75 "+classChild}>
                                            <h5>{p.usuario !== '' ? p.usuario : ses.nombre}</h5>
                                            {p.incidencia !== '' && <h6>Incidencia: {p.incidencia}</h6>}
                                            {p.curso !== '' && <h6>Curso: {p.curso}</h6>}
                                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: p.mensaje }}></p>
                                            <button type="button" onClick={()=>{this.abreModal('')}} style={{marginRight: '20px'}} className="mb-2 btn btn-primary btn-rounded waves-effect waves-light m-t-5">Responder</button>
                                            <span className="cd-date">{p.fechacreado}</span>
                                        </div>
                                    </div>
                                })
                              }
                              </section>
                            </CardBody>
                            <Modal isOpen={this.state.modal} toggle={this.cierraModal} autoFocus={true} size = "lg">
                                  <ModalHeader toggle={this.cierraModal}>
                                      Nuevo Post
                                  </ModalHeader>
                                  <ModalBody>
                                  <Form method="post">
                                    {this.state.error_dlg &&
                                      <FormGroup row>
                                        <Col md="12" lg="12" xl="12">
                                          <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                        </Col>
                                      </FormGroup>
                                    }
                                    <FormGroup row>
                                      <Label for="example-text-input" className="col-sm-12 col-form-label">Motivo de la incidencia</Label>
                                      <Col sm="12">
                                        <Select
                                            name="incidencia"
                                            onChange={(e)=>this.actualizaRespuestasSelect('incidencia',e.value)}
                                            options={[
                                              { label: "Problema con el curso", value: "Problema con el curso" },
                                              { label: "Problema con el contenido", value: "Problema con el contenido" },
                                              { label: "Problema con el chat", value: "Problema con el chat" },
                                              { label: "Problema con el foro", value: "Problema con el foro" }
                                            ]}
                                            className="select2"
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                      <Label for="example-text-input" className="col-sm-12 col-form-label">Selecciona el curso</Label>
                                      <Col sm="12">
                                        <Select
                                            name="curso"
                                            onChange={(e)=>this.actualizaRespuestasSelect('curso',e.value)}
                                            options={this.state.cursos}
                                            className="select2"
                                        />
                                      </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                      <Label for="example-text-input" className="col-sm-12 col-form-label">Mensaje</Label>
                                      <Col sm="12">
                                        <SunEditor name="text"
                                            setOptions={{height: 200}}
                                            onChange={this.rellenaTexto}
                                        />
                                      </Col>
                                    </FormGroup>
                                    </Form>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button type="button" color="secondary" className="waves-effect" onClick={()=>{this.cierraModal()}}>Cancelar</Button>
                                      <Button type="button" color="primary" className="waves-effect waves-light" onClick={()=>{this.postearMensaje()}}>Enviar</Button>
                                  </ModalFooter>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Foro));
