import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty } from '../../auxiliares/funciones';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

class Extras extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "Biblioteca", link : "/curso/"+this.props.match.params.id+"/biblioteca" },
          ],
          course : course,
          visible:-1,
          biblioteca: []
        }
    }

    componentDidMount(){
      showLoader(true);
      this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
      const ses = getLoggedInUser()
      const grupoid = getProfile() === 4 ? this.props.match.params.id : 'null'
      fetch(CONFIG.api + "Cursos?action=obtieneBiblioteca&token=" + ses.token+"&cursoid="+this.state.course.curso+"&grupoid="+grupoid)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            this.setState({biblioteca: result3})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    toggle = (id) =>{
        this.setState({visible: this.state.visible === id ? -1 : id})
    }

    verDocumento = (doc) =>{
      const win = window.open(doc.url+doc.nombre, '_blank');
      win.focus();
    }

    render() {
        const bib = this.state.biblioteca
        return (
                <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                    <Col md="12" lg="12" xl="12">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">Biblioteca</h4>
                            </CardHeader>
                            <CardBody>
                                <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                            </CardBody>
                            {!isEmpty(bib) &&
                            <CardBody>
                              <Row>
                                {bib.map((b,id)=>{
                                  const icon = b.nombre.toLowerCase().endsWith('.pdf') ? 'pdf' :
                                  b.nombre.toLowerCase().endsWith('.doc') || b.nombre.toLowerCase().endsWith('.docx') ? 'word' :
                                  b.nombre.toLowerCase().endsWith('.xls') || b.nombre.toLowerCase().endsWith('.xlsx') ? 'excel' : 'image'
                                  return <Col md="6" lg="6" xl="3" key={id}>
                                            <Card>
                                                <CardHeader style={{textAlign: 'center', fontSize: '55px'}}>
                                                  <i className={"far fa-file-"+icon}></i>
                                                </CardHeader>
                                                <CardBody style={{textAlign: 'center'}}>
                                                    <h4 className="card-title mb-4" style={{textAlign: 'left'}}>{b.nombre}</h4>
                                                    <Button type="button" color="primary" onClick={()=>{this.verDocumento(b)}} className="btn btn-primary waves-effect waves-light">Visualizar</Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                  })}
                                </Row>
                            </CardBody>
                            }
                            {!isEmpty(bib) &&
                            <CardBody>
                                <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                            </CardBody>
                            }
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Extras));
