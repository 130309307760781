import React, { Component } from 'react';
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getProfile } from '../../auxiliares/funciones';
import { authProtectedRoutes } from "../../routes/";


class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu:0
        };
    }


    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
          if (this.props.location.pathname === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
          }
        }
        if (matchingMenuItem) {
          this.activateParentDropdown(matchingMenuItem);
        }
      }

      activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
          parent.classList.add("active"); // li
          const parent2 = parent.parentElement;
          parent2.classList.add("active"); // li
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active"); // li
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add("active"); // li
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add("active"); // li
                 const parent6 = parent5.parentElement;
              if (parent6) {
                parent6.classList.add("active"); // li
              }
              }
            }
          }
        }
        return false;
    };

    render() {
        const profileID = getProfile()
        const authOptions = authProtectedRoutes.filter(route => route.profiles.includes(profileID) && route.menu !== undefined)
        let menus = [...new Set(authOptions.map(item => item.menu))];
        menus.forEach((element,id) => {
          menus[id] = {name : menus[id],options : authOptions.filter(route => route.menu === element)}
        })
        return (
            <React.Fragment>
            <div className="container-fluid">
                <div className="topnav">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                        <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                            <ul className="navbar-nav">
                            {menus.map((m,id) => {
                              return m.options.length === 1 ?
                                  (<li className="nav-item" key={id}>
                                      <Link className="nav-link" to={m.options[0].path}>
                                          <i className={m.options[0].icon}></i>{m.name}
                                      </Link>
                                  </li>)
                                :
                                  (<li className="nav-item dropdown" key={id}>
                                    <Link
                                        onClick={e => { e.preventDefault(); this.setState({ menu: id }); }}
                                        className="nav-link dropdown-toggle arrow-none"
                                        to={m.options[0].path}
                                        id={m.options[0].icon}
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                    >
                                        <i className={m.options[0].icon}></i>{m.name}
                                    </Link>
                                    <div className={this.state.menu === id ? "dropdown-menu dropdown-menu-left show" : "dropdown-menu dropdown-menu-left"} aria-labelledby={m.name}>
                                      {m.options.map((o,oid) => (
                                        <Link key={oid} to={o.path} className="dropdown-item">{o.name}</Link>
                                      ))}
                                    </div>
                                  </li>)
                            })}
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
            </React.Fragment>
        );
    }
}
const mapStatetoProps = state => {
    const { is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topbarTheme, isPreloader } = state.Layout;
    return {  is_toggle, leftSideBarType, layoutType, leftSideBarTheme, layoutWidth, topbarTheme, isPreloader };
}

export default withRouter(connect(mapStatetoProps, {})(Navbar));
