import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button, Form, Table, Label, Input, FormGroup, ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import { connect } from "react-redux";
import CONFIG from '../../../CONFIG.json';
import { Link, withRouter } from "react-router-dom";
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty, htmlToText } from '../../../auxiliares/funciones';

import SweetAlert from "react-bootstrap-sweetalert";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../auxiliares/breadcrumb/actions";


class Texto extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "", link : "/curso/"+this.props.match.params.id+"/actividad/"+this.props.match.params.id2},
          ],
          muestraMsg:false,
          course : course,
          actividad: {},
          respuesta: '',
          respuestaA: '',
          alumnos:[],
          alumnosO:[],
          respondido: false,
          error:'',
          error_dlg: false,
          modal: false,
          texto:'',
          nota:'',
          actividadid:0
        }
    }

    componentDidMount(){
      showLoader(true);
      const ses = getLoggedInUser()
      const action = getProfile() === 4 ? 'Grupo' : 'Curso'
      fetch(CONFIG.api + "Actividad?action=obtieneActividadCursoPor"+action+"&token=" + ses.token+"&actividadid="+this.props.match.params.id2+"&"+action+"="+this.props.match.params.id)
        .then(res => res.json())
        .then((result) => {
          getProfile() !== 2 && showLoader(false);
          if (result.Error === undefined) {
            let bread = this.state.breadcrumbItems
            bread[3].title = result.nombre
            this.props.setBreadcrumbItems(this.state.course.nombre, bread);
            const respondido = result.respuesta !== '' ? true : false
            this.setState({actividad:result, respondido:respondido})
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.muestraError(result.Error)
          }
      })
      if(getProfile() === 2){
        fetch(CONFIG.api + "Actividad?action=obtieneActividadesAlumnos&token=" + ses.token+"&actividadid="+this.props.match.params.id2)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              this.setState({alumnos:result, alumnosO: result})
              showLoader(false);
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
      }
    }

    abreModal = (actividadid,respuesta)=> {
        this.setState({modal: true, actividadid: actividadid, respuestaA: respuesta})
    }

    puntuarActividad = () =>{
      const nota = document.getElementById('nota').value
      if(nota === ''){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Datos incorrectos",texto:'Debes poner una puntuación.'}})
      }else if(parseInt(nota)>10){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Datos incorrectos",texto:'La puntuación máxima es 10.'}})
      }else{
        showLoader(true);
        const ses = getLoggedInUser()
        let notastutor = this.state.texto
        notastutor = notastutor.replaceAll('&','%26');
        fetch(CONFIG.api + "Actividad?action=corrigeActividadAlumno&token=" + ses.token+"&id="+this.state.actividadid+"&puntuacion="+nota+"&notastutor="+encodeURI(notastutor))
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              window.location.reload()
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
      }
    }

    rellenaTexto = (texto) =>{
      this.setState({texto: texto})
    }

    cierraModal = ()=> {
        this.setState({modal: false, actividadid: 0,error_dlg:false})
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    presentarActividad = () => {
      if(this.state.respuesta.length <10 ){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Revisa tu respuesta",texto:'No seas escueto a la hora de responder.'}})
      }else{
        this.setState({respondido:true})
        showLoader(true);
        const ses = getLoggedInUser()
        let respuesta = this.state.respuesta
        respuesta = respuesta.replaceAll('&','%26');
        fetch(CONFIG.api + "Actividad?action=registraActividad&token=" + ses.token+"&actividadid="+this.props.match.params.id2+"&grupoid="+this.props.match.params.id+"&respuesta="+encodeURI(respuesta))
          .then(res => res.json())
          .then((result) => {
            showLoader(false);
            if (result.Error === undefined) {
              this.setState({muestraMsg:true, msg:{tipo:'success',titulo:"Actividad entregada",texto:'Has presentado tu respuesta, ahora está a la espera de la correcion del tutor. ¡Buen trabajo!'}})
              this.setState({respondido:true})
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              this.muestraError(result.Error)
              this.setState({respondido:false})
            }
        })
      }
    }

    rellenaRespuesta = (respuesta) =>{
      this.setState({respuesta: respuesta})
    }

    buscaTabla = (input) =>{
        const datos = this.state.alumnosO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({alumnos: datos})
    }

    render() {
        const perfil = getProfile()
        const actividad = this.state.actividad
        const alumnos = this.state.alumnos
        console.log(alumnos)
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {!isEmpty(actividad) &&
                    <Col md="8" lg="8" xl="8">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">{actividad.nombre}</h4>
                            </CardHeader>
                            <CardBody>
                              {this.state.muestraMsg &&
                                <SweetAlert
                                type={this.state.msg.tipo}
                                title={this.state.msg.titulo}
                                onConfirm={() => this.setState({ muestraMsg: false })}
                                >
                                {this.state.msg.texto}
                                </SweetAlert>
                              }
                              <h4 className="mb-0">Descripción:</h4>
                              <p>{htmlToText(actividad.descripcion)}</p>
                              <h4 className="mb-0">Actividad: </h4>
                              <p>{htmlToText(actividad.enunciado)}</p>
                            </CardBody>
                            {perfil === 4 &&
                            <CardBody>
                              <h4 className="mb-0">Respuesta: </h4>
                              <Form method="post">
                                <SunEditor name="respuesta"
                                    defaultValue={actividad.respuesta}
                                    autoFocus={!this.state.respondido}
                                    disable={this.state.respondido}
                                    setOptions={{height: 300}}
                                    onChange={this.rellenaRespuesta}
                                    showToolbar={!this.state.respondido}
                                />
                              </Form>
                            </CardBody>
                            }
                            {perfil === 4 &&
                            <CardBody>
                              {this.state.respondido ? <Button type="button" color="secondary" style={{cursor: 'not-allowed'}} className="waves-effect" disabled>Entregado</Button>
                              : <Button onClick={()=>{this.presentarActividad()}} color="success" className="waves-effect">Entregar</Button>
                              }
                            </CardBody>
                            }
                        </Card>
                    </Col>
                  }

                    <Col md="4" lg="4" xl="4">
                      <Card>
                        {!isEmpty(actividad) && perfil === 4 && this.state.respondido &&
                          <CardHeader>
                            <h5 className="mb-0">Actividad presentada:</h5><h6 className="mb-0"> {actividad.fechacreado === '' ? 'Ahora' : actividad.fechacreado}</h6>
                            <h5 className="mb-0">Estado:</h5><h6 className="mb-0"> {actividad.estado === '' ? 'Entregado' : actividad.estado}</h6>
                            <h5 className="mb-0">Puntuación:</h5><h6 className="mb-0"> {actividad.puntuacion}</h6>
                            <h5 className="mb-0">Comentario del tutor:</h5><h6 className="mb-0" dangerouslySetInnerHTML={{ __html: actividad.notastutor }}></h6>
                          </CardHeader>
                        }
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
                { perfil === 2 &&
                  <Row>
                  {this.state.error_dlg &&
                      <Col md="12" lg="12" xl="12">
                        <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                      </Col>
                  }
                      <Col md="12" lg="12" xl="12">
                        <Card>
                          <CardBody>
                            <div className="table-responsive mt-4">
                                <FormGroup>
                                    <Label className="control-label">Buscar</Label>
                                    <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'actividades')} type="text" name="buscar"/>
                                </FormGroup>
                                <Table className="table table-hover mb-0 table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th style={{textAlign: 'center'}}><i className="dripicons-arrow-down"></i></th>
                                            <th>Fecha presentado</th>
                                            <th>Grupo</th>
                                            <th>Alumno</th>
                                            <th>Fecha corregido</th>
                                            <th>Puntuación</th>
                                            <th>estado</th>
                                        </tr>
                                    </thead>
                                    {!isEmpty(alumnos) &&
                                    <tbody>
                                      {alumnos.map((a,id)=>{
                                        return <tr key={id}>
                                                  {a.estado === 'Corregido' ?
                                                    <td></td>
                                                  : <td style={{textAlign: 'center'}}><Button type="button" color="primary" className="waves-effect waves-light" onClick={()=> this.abreModal(a.actid,a.respuesta)}>Corregir</Button></td>
                                                  }
                                                  <td>{a.fechacreado}</td>
                                                  <td>{a.grupo}</td>
                                                  <td>{a.usuario}</td>
                                                  <td>{a.fechacorregido}</td>
                                                  <td>{a.puntuacion}</td>
                                                  <td>{a.estado}</td>
                                                </tr>
                                      })}
                                    </tbody>
                                    }
                                </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Modal isOpen={this.state.modal} toggle={this.cierraModal} autoFocus={true} size = "lg">
                            <ModalHeader toggle={this.cierraModal}>
                                Corregir actividad
                            </ModalHeader>
                            <ModalBody dangerouslySetInnerHTML={{__html: this.state.respuestaA}}></ModalBody>
                            <ModalBody>
                            <Form method="post">
                              {this.state.error_dlg &&
                                <FormGroup row>
                                  <Col md="12" lg="12" xl="12">
                                    <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                  </Col>
                                </FormGroup>
                              }
                              <FormGroup row>
                                <Col md="12" lg="12" xl="12">
                                  <Label className="control-label">Puntuación</Label>
                                  <Input className="form-control width20" autoComplete="off" type="number" id="nota"/>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label for="example-text-input" className="col-sm-12 col-form-label">Anotación</Label>
                                <Col sm="12">
                                  <SunEditor name="text"
                                      setOptions={{height: 200}}
                                      onChange={this.rellenaTexto}
                                  />
                                </Col>
                              </FormGroup>
                              </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="secondary" className="waves-effect" onClick={()=>{this.cierraModal()}}>Cancelar</Button>
                                <Button type="button" color="primary" className="waves-effect waves-light" onClick={()=>{this.puntuarActividad()}}>Puntuar</Button>
                            </ModalFooter>
                      </Modal>
                  </Row>
                }
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Texto));
