import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button, Form, Collapse, Table,
  Modal, ModalHeader, ModalBody,  ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { connect } from "react-redux";
import CONFIG from '../../CONFIG.json';
import { Link, withRouter } from "react-router-dom";
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty } from '../../auxiliares/funciones';

import SweetAlert from "react-bootstrap-sweetalert";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";


class Foro extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "Foros", link : "/curso/"+this.props.match.params.id+"/foro"},
          ],
          course : course,
          foros:[],
          modal: false,
          foroid:0,
          visible: -1,
          texto:'',
          error:'',
          error_dlg: false
        }
    }

    abreModal = (foroid)=> {
        this.setState({modal: true, foroid: foroid})
    }

    cierraModal = ()=> {
        this.setState({modal: false, foroid: 0,error_dlg:false})
    }

    rellenaTexto = (texto) =>{
      this.setState({texto: texto})
    }

    creaTema = () =>{
      let titulo = document.getElementById('tituloTema').value
      if(titulo === '' || this.state.texto === ''){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Datos incorrectos",texto:'Debes rellenar el titulo y el contenido.'}})
      }else{
        showLoader(true);
        const ses = getLoggedInUser()
        let mensaje = this.state.texto
        mensaje = mensaje.replaceAll('&','%26');
        titulo = titulo.replaceAll('&','%26');
        fetch(CONFIG.api + "Foros?action=crearTema&token=" + ses.token+"&foroid="+this.state.foroid+"&titulo="+encodeURI(titulo)+"&mensaje="+encodeURI(mensaje))
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              this.props.history.push("/curso/"+this.props.match.params.id+"/foro/"+this.state.foroid+"/"+result.id);
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
      }
    }

    componentDidMount(){
      showLoader(true);
      const ses = getLoggedInUser()
      const action = getProfile() === 4 ? 'grupo' : 'curso'
      fetch(CONFIG.api + "Foros?action=forospor"+action+"&token=" + ses.token+"&"+action+"id="+this.props.match.params.id+"&cursoid="+this.state.course.curso)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            this.setState({foros:result})
            fetch(CONFIG.api + "Foros?action=temaspor"+action+"&token=" + ses.token+"&"+action+"id="+this.props.match.params.id)
              .then(res2 => res2.json())
              .then((result2) => {
                if (result2.Error === undefined) {
                  this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
                  const temas = result.map((r,id)=>{
                    return result2.filter(res => res.foroid === r.id)
                  })
                  this.setState({temas: temas})
                  showLoader(false);
                } else if (result2.Caducada !== undefined) {
                  this.props.history.push({
                    pathname: '/login',
                    search: '',
                    state: { caducada: true, url: this.props.location.pathname}
                  });
                }else{
                  this.muestraError(result2.Error)
                  showLoader(false);
                }
            })
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            showLoader(false);
            this.muestraError(result.Error)
          }
      })
    }

    borrarTema = (temaid) =>{
        showLoader(true);
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Foros?action=borrarTema&token=" + ses.token+"&temaid="+temaid)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              window.location.reload()
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    toggle = (id) =>{
        this.setState({visible: this.state.visible === id ? -1 : id})
    }

    render() {
        const foros = this.state.foros
        console.log(foros)
        const temas = this.state.temas
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {!isEmpty(foros) &&
                    <Col md="9" lg="9" xl="9">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">Foros</h4>
                            </CardHeader>
                            {this.state.muestraMsg &&
                              <SweetAlert
                              type={this.state.msg.tipo}
                              title={this.state.msg.titulo}
                              onConfirm={() => this.setState({ muestraMsg: false })}
                              >
                              {this.state.msg.texto}
                              </SweetAlert>
                            }
                            <CardBody>
                              <div id="accordion">
                              {foros.map((f,id)=>{
                                return <div className="card mb-1 shadow-none" key={id}>
                                        <Link to="#" className="text-dark" onClick={()=>this.toggle(id)} style={{ cursor : "pointer" }}>
                                            <Row className="card-header p-3 noMargin">
                                              <Col md="6" lg="6" xl="6">
                                                <h6 className="m-0 font-size-14">
                                                      {f.nombre}
                                                </h6>
                                                <small>
                                                      {f.descripcion}
                                                </small>
                                              </Col>
                                              <Col md="2" lg="2" xl="2" style={{textAlign: 'center'}}>
                                                <h6 className="m-0 font-size-14">
                                                      Temas
                                                </h6>
                                                <small>
                                                      {f.temas}
                                                </small>
                                              </Col>
                                              <Col md="2" lg="2" xl="2" style={{textAlign: 'center'}}>
                                                <h6 className="m-0 font-size-14">
                                                      Mensajes
                                                </h6>
                                                <small>
                                                      {f.mensajes}
                                                </small>
                                              </Col>
                                              <Col md="2" lg="2" xl="2" style={{textAlign: 'center'}}>
                                                <h6 className="m-0 font-size-14">
                                                      Últ. mensaje
                                                </h6>
                                                <small>
                                                     {f.ultmensaje}
                                                </small>
                                              </Col>
                                            </Row>
                                          </Link>
                                          <Collapse isOpen={this.state.visible === id}>
                                              <CardBody>
                                                <Button onClick={()=>{this.abreModal(f.id)}} color="info" className="waves-effect">Crear tema</Button>
                                              </CardBody>
                                              {!isEmpty(temas) && !isEmpty(temas[id]) &&
                                              <CardBody>
                                                <div className="table-responsive">
                                                    <Table className="table table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Tema</th>
                                                                <th>Nº Mensajes</th>
                                                                <th>Último Mensaje</th>
                                                                {getProfile() === 2 &&
                                                                <th></th>
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                          {temas[id].map((t,id)=>{
                                                            return<tr key={id}>
                                                                      <td><Link to={'/curso/'+this.props.match.params.id+"/foro/"+f.id+"/"+t.id}>{t.nombre}</Link></td>
                                                                      <td>{t.mensajes}</td>
                                                                      <td>De {t.usuario} en {t.ultmensaje}</td>
                                                                      {getProfile() === 2 &&
                                                                      <td><button type="button" onClick={()=>{this.borrarTema(t.id)}} className="mb-2 btn btn-secondary btn-rounded waves-effect waves-light m-t-5">Borrar</button></td>
                                                                      }
                                                                  </tr>
                                                            })}
                                                        </tbody>
                                                      </Table>
                                                </div>
                                              </CardBody>
                                              }
                                          </Collapse>
                                        </div>
                              })}
                              </div>
                            </CardBody>
                            <Modal isOpen={this.state.modal} toggle={this.cierraModal} autoFocus={true} size = "lg">
                                  <ModalHeader toggle={this.cierraModal}>
                                      Nuevo Tema
                                  </ModalHeader>
                                  <ModalBody>
                                  <Form method="post">
                                    {this.state.error_dlg &&
                                      <FormGroup row>
                                        <Col md="12" lg="12" xl="12">
                                          <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                        </Col>
                                      </FormGroup>
                                    }
                                    <FormGroup row>
                                        <Label for="example-text-input" className="col-sm-12 col-form-label">Título</Label>
                                        <Col sm="12">
                                            <Input className="form-control" id="tituloTema" type="text"/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                      <Label for="example-text-input" className="col-sm-12 col-form-label">Contenido</Label>
                                      <Col sm="12">
                                        <SunEditor name="text"
                                            setOptions={{height: 200}}
                                            onChange={this.rellenaTexto}
                                        />
                                      </Col>
                                    </FormGroup>
                                    </Form>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button type="button" color="secondary" className="waves-effect" onClick={()=>{this.cierraModal()}}>Cancelar</Button>
                                      <Button type="button" color="primary" className="waves-effect waves-light" onClick={()=>{this.creaTema()}}>Crear</Button>
                                  </ModalFooter>
                            </Modal>
                        </Card>
                    </Col>
                  }

                    <Col md="3" lg="3" xl="3">
                      <Card>
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Foro));
