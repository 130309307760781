import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button, Table, Input, FormGroup, Label} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import { showLoader,getLoggedInUser, workWithLocalStorage, getProfile } from '../../auxiliares/funciones';
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

class Cuestionario extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "Cuestionario", link : "/curso/"+this.props.match.params.id+"/cuestionario" },
          ],
          course : course,
          respuestas: {},
          muestraMsg: false,
          campos : ['age','gender','academygrade','province','professionalcategory','schedulecourse','schedulepercentage','Bussinesssize','1_1','1_2','2_1','2_2','3_1','3_2','4_1_1','4_2_1','4_1_2','4_2_2','5_1','5_2','6_1','6_2','7_1','7_2','8_1','8_2','9_1','9_2','9_3','9_4','9_5','10']
        }
    }

    componentDidMount(){
      this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
      showLoader(false);
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    actualizaRespuestas = (input) =>{
      const nombre = input.target.name
      const respuesta = input.target.value
      let res = this.state.respuestas
      res[nombre] = respuesta
      this.setState({respuestas:res})
    }

    actualizaRespuestasSelect = (nombre,respuesta) =>{
      let res = this.state.respuestas
      res[nombre] = respuesta
      this.setState({respuestas:res})
    }

    grabarCuestionario = () =>{
      let res = this.state.respuestas
      let camposOK = true
      this.state.campos.forEach((c, i) => {
        if(!res[c]){
          camposOK = false
        }
      });
      if(camposOK){
        if(res.academygrade === '9' && (res.academygradetext === undefined || res.academygradetext === '')){
          camposOK = false
          this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Faltan datos",texto:'Debes de especificar tu titulación actual en el campo de texto'}})
        }else if(res.professionalcategory === '5' && (res.professionalcategorytext === undefined || res.professionalcategorytext === '')){
          camposOK = false
          this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Faltan datos",texto:'Debes de especificar tu categoria profesional en el campo de texto'}})
        }
        if(camposOK){
          showLoader(true);
          const ses = getLoggedInUser()
          const form = Object.keys(res).map(k => k + '=' + res[k]).join(':');
          fetch(CONFIG.api + "Cuestionario?action=hacer&token=" + ses.token+"&grupoid="+this.props.match.params.id+"&preguntas="+form)
            .then(res => res.json())
            .then((result) => {
              showLoader(false);
              if (result.Error === undefined) {
                this.setState({muestraMsg:true, msg:{tipo:'success',titulo:"!Cuestionario enviado!",texto:'Gracias por participar'}})
              } else if (result.Caducada !== undefined) {
                this.props.history.push({
                  pathname: '/login',
                  search: '',
                  state: { caducada: true, url: this.props.location.pathname} 
                });
              }else{
                this.setState({muestraMsg:true, msg:{tipo:'error',titulo:"",texto:result.Error}})
              }
          })
        }
      }else{
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Faltan datos",texto:'Revisa que hayas respondido todas las cuestiones'}})
      }
    }

    render() {
        return (
                <React.Fragment>
                {this.state.muestraMsg &&
                  <SweetAlert
                  type={this.state.msg.tipo}
                  title={this.state.msg.titulo}
                  onConfirm={() => this.setState({ muestraMsg: false })}
                  >
                  {this.state.msg.texto}
                  </SweetAlert>
                }
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                  <Col md="12" lg="12" xl="12">
                    <Card>
                        <CardHeader>
                          <h4 className="mb-0">Cuestionario sobre el curso</h4>
                        </CardHeader>
                        <CardBody>
                            <h5>Para evaluar la calidad de las acciones formativas es necesaria su opinión como alumno/a, acerca de los distintos aspectos del curso en el que ha participado</h5>
                            <h6>Le rogamos responda a todas y cada una de las preguntas de este formulario.</h6>
                        </CardBody>
                        <CardBody>
                            <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                        </CardBody>
                        <CardBody>
                          <Row>
                            <Col md="2" lg="2" xl="2" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Edad</Label>
                                  <Input className="form-control" onChange={(e)=>this.actualizaRespuestas(e)} type="number" name="age"/>
                              </FormGroup>
                            </Col>
                            <Col md="2" lg="2" xl="2" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Sexo</Label>
                                  <Select
                                      name="gender"
                                      onChange={(e)=>this.actualizaRespuestasSelect('gender',e.value)}
                                      options={[
                                        { label: "Mujer", value: "0" },
                                        { label: "Hombre", value: "1" }
                                      ]}
                                      className="select2"
                                  />
                              </FormGroup>
                            </Col>
                            <Col md="3" lg="3" xl="3" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Provincia</Label>
                                  <Input className="form-control" onChange={(e)=>this.actualizaRespuestas(e)} type="text" name="province"/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4" lg="4" xl="4" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Horario del curso</Label>
                                  <Select
                                      name="schedulecourse"
                                      onChange={(e)=>this.actualizaRespuestasSelect('schedulecourse',e.value)}
                                      options={[
                                        { label: "Dentro de la jornada laboral", value: "0" },
                                        { label: "Fuera de la jornada laboral", value: "1" },
                                        { label: "Ambas", value: "2" }
                                      ]}
                                      className="select2"
                                  />
                              </FormGroup>
                            </Col>
                            <Col md="8" lg="8" xl="8" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Porcentaje de la jornada laboral que abarca el curso</Label>
                                  <Select
                                      name="schedulepercentage"
                                      onChange={(e)=>this.actualizaRespuestasSelect('schedulepercentage',e.value)}
                                      options={[
                                        { label: "Menos del 25%", value: "0" },
                                        { label: "Entre el 25% al 50%", value: "1" },
                                        { label: "Más del 50%", value: "2" }
                                      ]}
                                      className="select2 width33"
                                  />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4" lg="4" xl="4" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Titulación actual</Label>
                                  <Select
                                      name="academygrade"
                                      onChange={(e)=>this.actualizaRespuestasSelect('academygrade',e.value)}
                                      options={[
                                        { label: "Sin titulación", value: "0" },
                                        { label: "Certificado de Profesionalidad Nivel 1", value: "11" },
                                        { label: "Formación Profesional Básica/Cualificación Profesional", value: "12" },
                                        { label: "Título de graduado E.S.O./Graduado escolar", value: "1" },
                                        { label: "Certificado de Profesionalidad Nivel 2", value: "21" },
                                        { label: "Título de Bachiller", value: "2" },
                                        { label: "Título de Técnico/ FP grado medio", value: "3" },
                                        { label: "Título Profesional enseñanzas música-danza;artes", value: "41" },
                                        { label: "Certificado de Profesionalidad Nivel 3", value: "42" },
                                        { label: "Título de Técnico Superior/ FP grado superior", value: "4" },
                                        { label: "E. universitarios 1º ciclo (Diplomatura-Grado)", value: "5" },
                                        { label: "E. universitarios 2º ciclo (Licenciatura-Máster)", value: "6" },
                                        { label: "E. universitarios 3º ciclo (Doctor)", value: "7" },
                                        { label: "Título de Doctor", value: "8" },
                                        { label: "Otra titulación - Carnet profesional", value: "101" },
                                        { label: "Otra titulación - Enseñanzas de escuelas oficiales de idiomas", value: "102" },
                                        { label: "Otra titulación (especificar)", value: "9" }
                                      ]}
                                      className="select2"
                                  />
                                  <Input className="form-control mt-3" placeholder="Escribir si has seleccionado 'Otra titulación'" onChange={(e)=>this.actualizaRespuestas(e)} type="text" name="academygradetext"/>
                              </FormGroup>
                            </Col>
                            <Col md="4" lg="4" xl="4" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Categoría profesional</Label>
                                  <Select
                                      name="professionalcategory"
                                      onChange={(e)=>this.actualizaRespuestasSelect('professionalcategory',e.value)}
                                      options={[
                                        { label: "Directivo/a", value: "0" },
                                        { label: "Mando Intermedio", value: "1" },
                                        { label: "Técnico/a", value: "2" },
                                        { label: "Trabajador/a cualificado/a", value: "3" },
                                        { label: "Trabajador/a de baja cualificación", value: "4" },
                                        { label: "Otra categoría (especificar)", value: "5" }
                                      ]}
                                      className="select2"
                                  />
                                  <Input className="form-control mt-3" placeholder="Escribir si has seleccionado 'Otra categoría'" onChange={(e)=>this.actualizaRespuestas(e)} type="text" name="professionalcategorytext"/>
                              </FormGroup>
                            </Col>
                            <Col md="4" lg="4" xl="4" className="mt-4">
                              <FormGroup>
                                  <Label className="control-label">Tamaño de la empresa del participante</Label>
                                  <Select
                                      name="Bussinesssize"
                                      onChange={(e)=>this.actualizaRespuestasSelect('Bussinesssize',e.value)}
                                      options={[
                                        { label: "De 1 a 9 empleos", value: "0" },
                                        { label: "De 10 a 49 empleos", value: "1" },
                                        { label: "De 50 a 99 empleos", value: "2" },
                                        { label: "De 100 a 250 empleos", value: "3" },
                                        { label: "De más de 250 empleos", value: "4" }
                                      ]}
                                      className="select2"
                                  />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" lg="12" xl="12" className="table-responsive mt-4">
                              <h5>Valore los siguientes aspectos del curso utilizando una escala de puntuación del 1 al 4.</h5>
                              <Table className="table table-hover mb-0 table-bordered">
                                  <thead className="thead-light">
                                      <tr>
                                          <th>1. Organización del curso</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>1.1 El curso ha estado bien organizado (información, cumplimiento fechas y de horarios, entrega material)</td>
                                        <td><FormGroup check><Input type="radio" name="1_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="1_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="1_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="1_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>1.2 El número de alumnos del grupo ha sido adecuado para el desarrollo del curso</td>
                                        <td><FormGroup check><Input type="radio" name="1_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="1_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="1_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="1_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>2. Contenidos y metodología de impartición</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>2.1 Los contenidos del curso han respondido a mis necesidades formativas</td>
                                        <td><FormGroup check><Input type="radio" name="2_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="2_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="2_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="2_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>2.2 Ha habido una combinación adecuada de teoría y aplicación práctica</td>
                                        <td><FormGroup check><Input type="radio" name="2_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="2_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="2_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="2_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>3. Duración y horario</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>3.1 La duración del curso ha sido suficiente según los objetivos y contenidos del mismo</td>
                                        <td><FormGroup check><Input type="radio" name="3_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="3_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="3_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="3_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>3.2 El horario ha favorecido la asistencia al curso</td>
                                        <td><FormGroup check><Input type="radio" name="3_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="3_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="3_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="3_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>4. Formadores / Tutores</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>4.1 (Formador) La forma de impartir o tutorizar el curso ha facilitado el aprendizaje</td>
                                        <td><FormGroup check><Input type="radio" name="4_1_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_1_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_1_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_1_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>4.1 (Tutor) La forma de impartir o tutorizar el curso ha facilitado el aprendizaje</td>
                                        <td><FormGroup check><Input type="radio" name="4_1_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_1_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_1_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_1_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>4.2 (Formador) Conocen los temas impartidos en profundidad</td>
                                        <td><FormGroup check><Input type="radio" name="4_2_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_2_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_2_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_2_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>4.2 (Tutor) Conocen los temas impartidos en profundidad</td>
                                        <td><FormGroup check><Input type="radio" name="4_2_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_2_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_2_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="4_2_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>5. Medios didácticos (guías, manuales, fichas…)</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>5.1 La documentación y materiales entregados son comprensibles y adecuados</td>
                                        <td><FormGroup check><Input type="radio" name="5_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="5_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="5_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="5_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>5.2 Los medios didácticos están actualizados</td>
                                        <td><FormGroup check><Input type="radio" name="5_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="5_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="5_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="5_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>6. Instalaciones y medios técnicos (pizarra, pantalla, proyector, TV, vídeo, ordenador, programas,máquinas, herramientas...)</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>6.1 El aula, el taller o las instalaciones han sido apropiadas para el desarrollo del curso</td>
                                        <td><FormGroup check><Input type="radio" name="6_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="6_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="6_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="6_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>6.2 Los medios técnicos han sido adecuados para desarrollar el contenido del curso (ordenadores, pizarra, proyector,TV, máquinas)</td>
                                        <td><FormGroup check><Input type="radio" name="6_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="6_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="6_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="6_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>7. Sólo cuando el curso se ha realizado en la modalidad a distancia, teleformación o mixta</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>7.1 Las guías tutoriales y los materiales didácticos han permitido realizar fácilmente el curso (impresos, aplicaciones)</td>
                                        <td><FormGroup check><Input type="radio" name="7_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="7_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="7_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="7_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>7.2 Se ha contado con medios de apoyo suficientes (tutorías individualizadas, correo y listas de distribución,teleconferencia, biblioteca virtual, buscadores…)</td>
                                        <td><FormGroup check><Input type="radio" name="7_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="7_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="7_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="7_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th colSpan={3}>8. Mecanismos para la evaluación del aprendizaje</th>
                                          <th>Sí</th>
                                          <th>No</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td colSpan={3}>8.1.Se ha dispuesto de pruebas de evaluación y autoevaluación que me permiten conocer el nivel de aprendizaje alcanzado</td>
                                        <td><FormGroup check><Input type="radio" name="8_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="8_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>8.2 El curso me permite obtener una acreditación donde se reconoce mi cualificación</td>
                                        <td><FormGroup check><Input type="radio" name="8_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="8_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>9. Valoración general del curso</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>9.1 Puede contribuir a mi incorporación al mercado de trabajo</td>
                                        <td><FormGroup check><Input type="radio" name="9_1" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_1" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_1" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_1" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>9.2 Me ha permitido adquirir nuevas habilidades/capacidades que puedo aplicar al puesto de trabajo</td>
                                        <td><FormGroup check><Input type="radio" name="9_2" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_2" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_2" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_2" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>9.3 Ha mejorado mis posibilidades para cambiar de puesto de trabajo en la empresa o fuera de ella</td>
                                        <td><FormGroup check><Input type="radio" name="9_3" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_3" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_3" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_3" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>9.4 He ampliado conocimientos para progresar en mi carrera profesional</td>
                                        <td><FormGroup check><Input type="radio" name="9_4" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_4" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_4" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_4" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                    <tr>
                                        <td>9.5 Ha favorecido mi desarrollo personal</td>
                                        <td><FormGroup check><Input type="radio" name="9_5" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_5" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_5" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="9_5" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                                  <thead className="thead-light">
                                      <tr>
                                          <th>10. Grado de satisfacción general con el curso</th>
                                          <th>1</th>
                                          <th>2</th>
                                          <th>3</th>
                                          <th>4</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                        <td>10.1 Grado de satisfacción general con el curso</td>
                                        <td><FormGroup check><Input type="radio" name="10" value={1} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="10" value={2} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="10" value={3} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                        <td><FormGroup check><Input type="radio" name="10" value={4} onChange={(e)=>this.actualizaRespuestas(e)}/></FormGroup></td>
                                    </tr>
                                  </tbody>
                              </Table>
                            </Col>
                            <Col md="12" lg="12" xl="12" className="mt-4">
                              <FormGroup>
                                <Label className="text-muted">11. Si desea realizar cualquier sugerencia u observación, por favor, utilice el espacio reservado a continuación</Label>
                                <textarea id="textarea" name="11" onChange={(e)=>this.actualizaRespuestas(e)} className="form-control" maxLength="225" rows="3"></textarea>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                        {getProfile() === 4 && <CardBody>
                            <Button color="success" onClick={()=>{this.grabarCuestionario()}} className="btn btn-success waves-effect waves-light">Grabar</Button>
                        </CardBody>
                        }
                        <CardBody>
                            <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                        </CardBody>
                    </Card>
                  </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Cuestionario));
