import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Collapse, CardFooter} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../../CONFIG.json';
import Paginacion from './paginacion';
import ListContenido from './listaCont';
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty, compruebaComienzoPalabra } from '../../../auxiliares/funciones';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../auxiliares/breadcrumb/actions";

class Extras extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "", link : ""},
          ],
          course : course,
          detalle: {},
          contenido: [],
          visible:-1,
          glosario: []
        }
    }

    componentDidMount(){
      const detalleid = 'glosario'
      const ses = getLoggedInUser()
      const grupoid = getProfile() === 4 ? this.props.match.params.id : 'null'
      fetch(CONFIG.api + "Cursos?action=contenidodetallepornombre&contenidoid="+this.props.match.params.id2+"&token=" + ses.token+"&nombre="+detalleid+"&grupoid="+grupoid)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            let bread = this.state.breadcrumbItems
            bread[3].title = result.unidad
            bread[3].link = "/curso/"+this.props.match.params.id+"/contenido/"+result.contenidoid+"/"+detalleid
            this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);

            fetch(CONFIG.api + "Cursos?action=contenidocursodetalle&token=" + ses.token+"&contenidoid="+this.props.match.params.id2+"&nombre="+detalleid)
              .then(res2 => res2.json())
              .then((result2) => {
                if (result2.Error === undefined) {
                  fetch(CONFIG.api + "Cursos?action=obtieneGlosarioPorContenido&token=" + ses.token+"&contenidoid="+this.props.match.params.id2+"&grupoid="+grupoid+"&cursoid="+this.state.course.curso)
                    .then(res3 => res3.json())
                    .then((result3) => {
                      showLoader(false);
                      if (result3.Error === undefined) {
                        const glosario = this.organizaGlosario(result3)
                        this.setState({contenido:result2 , detalle:result, glosario: glosario})
                      } else if (result3.Caducada !== undefined) {
                        this.props.history.push({
                          pathname: '/login',
                          search: '',
                          state: { caducada: true, url: this.props.location.pathname} 
                        });
                      }else{
                        this.setState({error:result2.Error ,error_dlg:true})
                      }
                  })
                } else if (result2.Caducada !== undefined) {
                  this.props.history.push({
                    pathname: '/login',
                    search: '',
                    state: { caducada: true, url: this.props.location.pathname} 
                  });
                }else{
                  showLoader(false);
                  this.setState({error:result2.Error ,error_dlg:true})
                }
            })
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            showLoader(false);
            this.setState({error:result.Error ,error_dlg:true})
          }
      })
    }

    organizaGlosario = (array) =>{
        let glos = []
        glos[0] = {nombre:'A-I', palabras : array.filter(p => compruebaComienzoPalabra('a','i',p.palabra))}
        glos[1] = {nombre:'J-Q', palabras : array.filter(p => compruebaComienzoPalabra('j','q',p.palabra))}
        glos[2] = {nombre:'R-Z', palabras : array.filter(p => compruebaComienzoPalabra('r','z',p.palabra))}
        return glos
    }

    getDetalle = (detalleid) =>{
      showLoader(true);
      window.scrollTo(0, 0);
      this.props.history.push("/curso/"+this.props.match.params.id+"/contenido/"+this.props.match.params.id2+"/detalle/"+detalleid);
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    toggle = (id) =>{
        this.setState({visible: this.state.visible === id ? -1 : id})
    }

    render() {
        const detalle = this.state.detalle
        const contenido = this.state.contenido
        const glos = this.state.glosario
        return (
                <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {!isEmpty(detalle) && !isEmpty(contenido) &&
                    <Col md="8" lg="8" xl="8">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">{detalle.unidad}</h4>
                            </CardHeader>
                            <CardBody>
                                <Paginacion
                                    getDetalle={this.getDetalle}
                                    anterior={contenido[0].anterior}
                                    siguiente={contenido[0].siguiente}
                                    grupoid={this.props.match.params.id}
                                    contenidoid={contenido[0].contenidoid}/>
                                <h4 className="card-title">{detalle.titulo}</h4>
                                {detalle.total !== '1/1' && <h6 className="card-subtitle font-14 text-muted">{detalle.total}</h6>}
                            </CardBody>
                            <CardBody>
                              <div id="accordion">
                                {glos.map((g,id)=>{
                                  return <div className="card mb-1 shadow-none" key={id}>
                                          <Link to="#" className="text-dark" onClick={()=>this.toggle(id)} style={{ cursor : "pointer" }}>
                                              <div className={"card-header p-3 "}>
                                                  <h6 className="m-0 font-size-14">{g.nombre}</h6>
                                              </div>
                                            </Link>
                                            <Collapse isOpen={this.state.visible === id}>
                                                <CardBody>
                                                    {g.palabras.map((p,id2)=>{
                                                       return <p key={id2}>
                                                                <strong>{p.palabra}: </strong>{p.definicion}
                                                              </p>
                                                    })}
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                  })}
                              </div>
                            </CardBody>
                            <CardFooter>
                                <Paginacion
                                    getDetalle={this.getDetalle}
                                    anterior={contenido[0].anterior}
                                    siguiente={contenido[0].siguiente}
                                    grupoid={this.props.match.params.id}
                                    contenidoid={contenido[0].contenidoid}/>
                            </CardFooter>
                        </Card>
                    </Col>
                  }
                  {!isEmpty(contenido) &&
                    <Col md="4" lg="4" xl="4">
                      <ListContenido getDetalle={this.getDetalle} grupoid={this.props.match.params.id} detalleid={'glosario'} contenido={contenido} titulo={detalle.titulo} />
                    </Col>
                  }
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Extras));
