import React, { Component } from 'react';
import {  CardBody, Collapse, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { showLoader } from '../../auxiliares/funciones';

class Evaluaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            examenes:this.props.examenes,
            visible:-1
        }
      }

      toggle = (id) =>{
          this.setState({visible: this.state.visible === id ? -1 : id})
      }

      componentDidMount(){
        window.scrollTo(0,280);
        showLoader(false);
      }

      render() {
          return (
              <React.Fragment>
                            <div id="accordion">
                              {this.state.examenes.map((c,id)=>{
                                return <div className="card mb-1 shadow-none" key={id}>
                                        <Link to="#" className="text-dark" onClick={()=>this.toggle(id)} style={{ cursor : "pointer" }}>
                                            <div className={"card-header p-3 "}>
                                                <h6 className="m-0 font-size-14">
                                                      {c.campos.nombre}  ({c.campos.fecha})  Nota: {c.campos.nota}
                                                </h6>
                                            </div>
                                          </Link>
                                          <Collapse isOpen={this.state.visible === id}>
                                              <CardBody>
                                                {c.preguntas.map((p,id)=>{
                                                  let form = {} // eslint-disable-next-line
                                                  c.preguntas.map((p)=>{
                                                      const s = p.respuestas.find(e => e.seleccionado === "1")
                                                      form[p.campos.id] = s.id
                                                  })
                                                  return (<FormGroup tag="fieldset" key={'p'+id}>
                                                            <h5>{p.campos.pregunta}</h5>
                                                            {p.respuestas.map((r,id2)=>{
                                                              return  (<FormGroup check key={'r'+id2}>
                                                                        <Label check>
                                                                          <Input type="radio" value={r.id} disabled checked={r.seleccionado === "1" || form[parseInt(p.campos.id)] === r.id ? true : false} name={id} />{' '}{r.respuesta}
                                                                          {(r.valor === '1') && <i style={{color:'green'}} className="dripicons-checkmark"></i>}
                                                                          {(form[parseInt(p.campos.id)] === r.id && r.valor === '0') && <i style={{color:'red'}} className="dripicons-cross"></i>}
                                                                        </Label>
                                                                      </FormGroup>)
                                                            })}
                                                          </FormGroup>
                                                          )
                                                })}
                                              </CardBody>
                                          </Collapse>
                                      </div>
                                })}
                              </div>
                  </React.Fragment>
          );
      }
}

export default Evaluaciones;
