import React, { Component } from 'react';
import { Container } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {  Col,   Row, Alert } from "reactstrap";

//Import Components
import Breadcrumb from "../../componentes/breadcrumb";
import CONFIG from '../../CONFIG.json';
import { getLoggedInUser, getProfile, workWithLocalStorage, getCourseInfo, showLoader } from '../../auxiliares/funciones';
import Navbar from "./Navbar";
import Topbar from "./Topbar";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpened: false,
            error_dlg: false,
            error: '',
            espera: false
        };
    }

    componentWillMount(){
      document.body.classList.remove("bodyLogin");
      let path = this.props.history.location.pathname
      if(path.split('/').length >= 3 && path.split('/')[1] === 'curso'){
        const idN = path.split('/')[2]
        let curso = getCourseInfo()
        const idV = curso !== null ? getProfile() === 4 ? curso.grupoid : curso.curso : 0
        if(idN !== idV){
          this.setState({espera:true})
          this.consultaCurso(idN)
        }
      }
    }

    componentDidMount() {
        //showLoader(true);
        window.scrollTo(0, 0);
        document.title = CONFIG.nombre +" | "+CONFIG.descripcion;
        document.body.setAttribute("data-layout", "horizontal");
    }

    consultaCurso = (id) =>{
      const action = getProfile() === 4 ? 'grupo' : 'curso'
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Cursos?action=cursopor"+action+"&token=" + ses.token+"&id="+id)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            if(result.matriculado === '0'){
              this.setState({error:'No existe ningun curso con ese valor' ,error_dlg:true, espera:false})
              showLoader(false);
              workWithLocalStorage('remove','course',{})
            }else{
              workWithLocalStorage('set','course',result)
              this.setState({espera:false})
            }
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.setState({error:result.Error ,error_dlg:true, espera:false})
            showLoader(false);
          }
          //showLoader(false);
      })
    }

    openMenu = e => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
    };

    render() {
        const ses = getLoggedInUser()

        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                <div id="layout-wrapper">
                    <header id="page-topbar">
                        <Topbar isMenuOpened={this.state.isMenuOpened} openLeftMenuCallBack={this.openMenu.bind(this)}/>
                        <div className="top-navigation">
                            <div className="page-title-content">
                                <Container fluid>
                                    <Breadcrumb/>
                                </Container>
                            </div>
                            <Navbar menuOpen={this.state.isMenuOpened} />
                        </div>
                    </header>
                    <div className="main-content">
                        <div className="page-content noPaddingBottom">
                          <Container fluid>
                            <h4 style={{"color":"#1a3a59"}}>Bienvenido/a {ses.nombre}</h4>
                          </Container>
                        </div>
                        <div className="page-content2 noMarginTop noPaddingTop">
                            <Container fluid>
                              {!this.state.espera &&
                                (this.state.error_dlg ?
                                  <Row>
                                      <Col md="12" lg="12" xl="12">
                                      {this.state.error_dlg &&<Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>}
                                      </Col>
                                  </Row>
                                : this.props.children)
                              }
                            </Container>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { isPreloader } = state.Layout;
      return { isPreloader };
};

export default withRouter(connect(mapStatetoProps, {})(Layout));
