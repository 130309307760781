import React, { Component } from 'react';
import {  Col,   Row, Card , CardBody, Alert, Table } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CONFIG from '../../CONFIG.json';
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty } from '../../auxiliares/funciones';

import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";


class Curso extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Inicio", link : "/app" },
                { title : "Cursos", link : "/cursos" },
                { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
                { title : "Calificaciones", link : "/curso/"+this.props.match.params.id+"/calificaciones" }
            ],
            course:course,
            calificaciones:[],
            apartados:[],
            alumnos:[]
        }
    }

    componentDidMount(){
        showLoader(true);
        this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
        const action = getProfile() === 4 ? 'grupo' : 'curso'
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Cursos?action=obtieneCalificaciones&token=" + ses.token+"&"+action+"id="+this.props.match.params.id)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              const alumnos = [...new Set(result.map(r => r.alumno))]
              const apartados = [...new Set(result.map(r => r.titulo))]
              this.setState({calificaciones:result, alumnos: alumnos, apartados:apartados})
              showLoader(false);
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname} 
              });
            }else{
              showLoader(false);
              this.setState({error:result.Error ,error_dlg:true})
            }
          })

    }

    toggle = (id) =>{
        this.setState({visible: this.state.visible === id ? -1 : id})
    }

    render() {
      const cal = this.state.calificaciones
      const alu = this.state.alumnos
      const apa = this.state.apartados
        return (
            <React.Fragment>
                <Row>
                  {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                  }
                    <Col md="12" lg="12" xl="12">
                      <Card>
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                          </CardBody>
                      </Card>
                    </Col>
                    <Col md="12" lg="12" xl="12">
                    {!isEmpty(this.state.calificaciones) &&
                    <Card>
                        <CardBody>
                          <div className="table-responsive mt-4">
                            <Table className="table table-hover mb-0 table-bordered">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Apartado</th>
                                        {alu.map((a,id)=>{
                                          return <th key={id}>{a}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                  {apa.map((ap,ida)=>{
                                      return cal.filter(c => c.titulo === ap).map((c,idc)=>{
                                          const icon = c.tipo === 'examen' ? 'dripicons-blog' : c.tipo === 'actividad' ? 'dripicons-document-edit' : 'dripicons-view-list'
                                          return <tr key={ida}>
                                                    <td><i className={icon} style={{paddingRight:'15px'}}></i>{c.titulo}</td>
                                                    {alu.map((a,id)=>{
                                                      return cal.filter(c => c.titulo === ap && c.alumno === a).map((p,idp)=>{
                                                        const nota = p.porcentaje === '' ? p.nota : p.porcentaje+' %'
                                                        return <td key={idp} style={{textAlign: 'right'}}>{nota}</td>
                                                      })
                                                    })}
                                                </tr>
                                       })
                                  })}
                                </tbody>
                                <tfoot className="thead-light">
                                    <tr>
                                        <th>Apartado</th>
                                        {alu.map((a,id)=>{
                                          return <th key={id}>{a}</th>
                                        })}
                                    </tr>
                                </tfoot>
                              </Table>
                            </div>
                          </CardBody>
                      </Card>
                      }
                    </Col>
                </Row>

                </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Curso));
