import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Collapse} from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../../CONFIG.json';
import { showLoader,getLoggedInUser, workWithLocalStorage, getProfile, isEmpty, compruebaComienzoPalabra } from '../../../auxiliares/funciones';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../auxiliares/breadcrumb/actions";

class Extras extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "Glosario", link : "/curso/"+this.props.match.params.id+"/glosario" },
          ],
          course : course,
          visible:-1,
          glosario: []
        }
    }

    componentDidMount(){
      this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
      const campo = getProfile() === 4 ? 'grupo' : 'curso'
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Cursos?action=obtieneGlosarioPorCurso&token=" + ses.token+"&"+campo+"id="+this.props.match.params.id)
        .then(res3 => res3.json())
        .then((result3) => {
          showLoader(false);
          if (result3.Error === undefined) {
            const glosario = this.organizaGlosario(result3)
            this.setState({glosario: glosario})
          } else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            this.setState({error:result3.Error ,error_dlg:true})
          }
      })
    }

    organizaGlosario = (array) =>{
        let glos = []
        glos[0] = {nombre:'A-I', palabras : array.filter(p => compruebaComienzoPalabra('a','i',p.palabra))}
        glos[1] = {nombre:'J-Q', palabras : array.filter(p => compruebaComienzoPalabra('j','q',p.palabra))}
        glos[2] = {nombre:'R-Z', palabras : array.filter(p => compruebaComienzoPalabra('r','z',p.palabra))}
        return glos
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    toggle = (id) =>{
        this.setState({visible: this.state.visible === id ? -1 : id})
    }

    render() {
        const glos = this.state.glosario
        return (
                <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {!isEmpty(glos) &&
                    <Col md="12" lg="12" xl="12">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">Glosario</h4>
                            </CardHeader>
                            <CardBody>
                                <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                            </CardBody>
                            <CardBody>
                              <div id="accordion">
                                {glos.map((g,id)=>{
                                  return <div className="card mb-1 shadow-none" key={id}>
                                          <Link to="#" className="text-dark" onClick={()=>this.toggle(id)} style={{ cursor : "pointer" }}>
                                              <div className={"card-header p-3 "}>
                                                  <h6 className="m-0 font-size-14">{g.nombre}</h6>
                                              </div>
                                            </Link>
                                            <Collapse isOpen={this.state.visible === id}>
                                                <CardBody>
                                                    {g.palabras.map((p,id2)=>{
                                                       return <p key={id2}>
                                                                <strong>{p.palabra}: </strong>{p.definicion}
                                                              </p>
                                                    })}
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                  })}
                              </div>
                            </CardBody>
                            <CardBody>
                                <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                            </CardBody>
                        </Card>
                    </Col>
                  }
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Extras));
