import React from "react";
import { Redirect } from "react-router-dom";

import Pageslogin from '../paginas/login';
import Logout from '../paginas/logout';
import ResetPass from '../paginas/recuperar-contrasena';
//Dashboard
import Main from '../paginas/principal';

//Cursos
import Cursos from "../paginas/cursos/";
import Curso from "../paginas/cursos/curso";
import ContenidoTexto from "../paginas/cursos/contenido/texto";
import ContenidoGlosario from "../paginas/cursos/contenido/glosario";
import ContenidoGlosarioCont from "../paginas/cursos/contenido/glosarioCont";
import ContenidoExtra from "../paginas/cursos/contenido/extras";
import Examen from "../paginas/cursos/contenido/examen";
import Actividad from "../paginas/cursos/contenido/actividad";
import Chat from "../paginas/cursos/chat";
import Foros from "../paginas/cursos/foros";
import Posts from "../paginas/cursos/posts";
import Biblioteca from "../paginas/cursos/biblioteca";
import Cuestionario from "../paginas/cursos/cuestionario";
import Calificaciones from "../paginas/cursos/calificaciones";

//Otros
import Mensajes from "../paginas/mensajes";
import Soporte from "../paginas/soporte";

//Tutor/Inspector
import Grupos from "../paginas/grupos/";
import GruposI from "../paginas/grupos/grupos";
import Informes from "../paginas/informes/";
import Seguimiento from "../paginas/informes/seguimiento";

import E404 from "../paginas/404";
import E500 from "../paginas/500";

const authProtectedRoutes = [
  // DashBoard
  { exact: true, path: "/app", component: Main, profiles:[1,2,3,4,5,6,7,8,9] },
  /*{ exact: true, path: "/", component: Main, profiles:[1,2,3,4,5,6,7,8,9] },*/

  //Tutor/Inspector
  { exact: true, path: "/grupos", component: Grupos , name:'Grupos', profiles:[2], icon:'dripicons-user-group', menu:'Grupos formativos',defaultProfile: [2]},
  { exact: true, path: "/grupos", component: GruposI , name:'Grupos', profiles:[3], icon:'dripicons-user-group', menu:'Grupos formativos',defaultProfile: [3]},
  { exact: true, path: "/informes", component: Informes , name:'Informes', profiles:[2,3], icon:'dripicons-document', menu:'Informes sobre grupos'},
  { exact: true, path: "/informe/:id", component: Informes , name:'Informes', profiles:[2]},
  { exact: true, path: "/seguimiento", component: Seguimiento , name:'Seguimiento', profiles:[2,5,6], icon:'dripicons-user-id', menu:'Seguimiento de alumnos'},

  //Cursos
  { exact: true, path: "/cursos", component: Cursos , name:'Cursos', profiles:[4], menu: 'Cursos', icon:'dripicons-checklist' ,defaultProfile: [4]},
  { exact: true, path: "/curso/:id", component: Curso , name:'Curso', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/contenido/:id2/detalle/:id3", component: ContenidoTexto , name:'Contenido', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/contenido/:id2/glosario", component: ContenidoGlosarioCont , name:'Glosario', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/glosario", component: ContenidoGlosario , name:'Glosario', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/contenido/:id2/:id3", component: ContenidoExtra , name:'Extras', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/examen/:id2", component: Examen , name:'Examen', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/actividad/:id2", component: Actividad , name:'Actividad', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/chat/:id2", component: Chat , name:'Chat', profiles:[2,4]},
  { exact: true, path: "/curso/:id/foros", component: Foros , name:'Foros', profiles:[2,4]},
  { exact: true, path: "/curso/:id/foro/:id2/:id3", component: Posts , name:'Posts', profiles:[2,4]},
  { exact: true, path: "/curso/:id/biblioteca", component: Biblioteca , name:'Biblioteca', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/calificaciones", component: Calificaciones , name:'Calificaciones', profiles:[1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/curso/:id/cuestionario", component: Cuestionario , name:'Cuestionario', profiles:[2,3,4]},

  //Otros
  { exact: true, path: "/mensajes/:id", component: Mensajes , name:'Mensajes', profiles:[2,4]},
  { exact: true, path: "/mensajes", component: Mensajes , name:'Mensajes', profiles:[2,4], icon:'dripicons-mail', menu:'Buzón de mensajes'},
  { exact: true, path: "/soporte", component: Soporte , name:'Soporte', profiles:[2,4], icon:'dripicons-information', menu:'Soporte técnico'},


  //OTROS 2
  { exact: true, path: "/500", component: E500 ,name:'Error 500', profiles:[0,1,2,3,4,5,6,7,8,9]},
  { exact: true, path: "/",  component: () => <Redirect to="/app" /> ,profiles:[0,1,2,3,4,5,6,7,8,9]}
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },
  { path: '/recuperar-contrasena', component: ResetPass },
  { path: "/404", component: E404 }
];

export { authProtectedRoutes, publicRoutes };
