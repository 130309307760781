import React, { Component } from 'react';
import CONFIG from '../CONFIG.json';
import { workWithLocalStorage, showLoader, getLoggedInUser } from '../auxiliares/funciones';

class Logout extends Component {

    constructor(props) {
        super(props);
        this.state = {  };
    }

    componentDidMount() {
      showLoader(true)
      const user = getLoggedInUser()
      fetch(CONFIG.api + "Sesion?action=logout&token=" + user.token)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            // Remove all Item from localstorage and redirect to login page
            workWithLocalStorage('remove','user','');
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { logout: true }
            });
          }
        })
    }

    render() {
        return (
            <React.Fragment>
               <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}


export default Logout;
