import React, { Component } from 'react';
import { PaginationItem, PaginationLink} from "reactstrap";
import { connect } from "react-redux";


class Paginacion extends Component {

    render() {
        return (
              <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                  {this.props.anterior === 'none' ?
                     <PaginationItem disabled>
                        <PaginationLink href="#" tabIndex="-1">{"<"} Anterior</PaginationLink>
                     </PaginationItem>
                   : <PaginationItem>
                          <PaginationLink className="bg-primary fontWhite" onClick={() => this.props.getDetalle(this.props.anterior)} href={"/curso/"+this.props.grupoid+"/contenido/"+this.props.contenidoid+"/detalle/"+this.props.anterior} tabIndex="-1">{"<"} Anterior</PaginationLink>
                      </PaginationItem>
                    }
                    {this.props.siguiente === 'none' ?
                      <PaginationItem disabled>
                          <PaginationLink href="#">Siguiente {">"}</PaginationLink>
                      </PaginationItem>
                    : <PaginationItem>
                        <PaginationLink className="bg-primary fontWhite" onClick={() => this.props.getDetalle(this.props.siguiente)} href={"/curso/"+this.props.grupoid+"/contenido/"+this.props.contenidoid+"/detalle/"+this.props.siguiente}>Siguiente {">"}</PaginationLink>
                      </PaginationItem>
                    }
                  </ul>
              </nav>
        );
    }
}

export default connect(null, { })(Paginacion);
