import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardHeader, CardText } from 'reactstrap';
import { connect } from "react-redux";
import CONFIG from '../CONFIG.json';
import { Link,withRouter } from "react-router-dom";
import { showLoader,getLoggedInUser, isEmpty, htmlToText } from '../auxiliares/funciones';

import ChatBox from 'react-chat-plugin';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../auxiliares/breadcrumb/actions";

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Buzon de mensajes", link : "/mensajes" }
          ],
          destinoid: this.props.match.params.id,
          usuarios:[],
          texto:'',
          error:'',
          intervalID: 0,
          ultimomensajeid:0,
          error_dlg: false,
          mensajes: []
        }
    }

    handleOnSendMessage = (message) => {
        const ses = getLoggedInUser()
        const time = +new Date()
        const mes = {
          author: {
            username: ses.nombre,
            id: ses.nombre,
            // avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
          },
          text: message,
          type: 'text',
          timestamp: time,
        }
        this.actualizaMensajes([mes])
        message = message.replaceAll('&','%26');
        fetch(CONFIG.api + "Mensajeria?action=enviarMensaje&token=" + ses.token+"&destinoid="+this.state.destinoid+"&mensaje="+encodeURI(message))
          .then(res2 => res2.json())
          .then((result2) => {
            if (result2.Error !== undefined) {
            this.borraMensaje(time)
            this.muestraError(result2.Error)
          }
      })
    }

    borraMensaje = (time)=>{
      let mess = this.state.mensajes
      mess = mess.filter((m)=> m.timestamp !== time)
      this.setState({mensajes: mess})
    }

    actualizaMensajes = (mensajes) =>{
      let mess = this.state.mensajes
      mensajes.forEach(m => {
        const ind = mess.findIndex(e => e.author.id === m.author.id && e.text === m.text)
        ind<0 && mess.push(m)
      });
      this.setState({mensajes: mess})
    }

    actualizaChat = (id) =>{
      const ses = getLoggedInUser()
      if(id === 0){
        this.setState({mensajes: [], error_dlg: false})
      }else{
        this.setState({error_dlg:false, destinoid: id})
        fetch(CONFIG.api + "Mensajeria?action=obtieneUltimosMensajes&token=" + ses.token+"&destinoid="+id+"&ultimoid="+this.state.ultimomensajeid)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
                if(result.length > 0){
                  const mensajes = result.map((m,id)=>{
                    return {
                      author: {
                        username: m.perfil === 'Tutor' ? m.usuario+' (Tutor)' : m.usuario+' (Estudiante)',
                        id: m.usuario,
                        // avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
                      },
                      text: htmlToText(m.mensaje),
                      type: 'text',
                      timestamp: parseInt(m.fecha+'000'),
                    }
                  })
                  this.setState({ultimomensajeid: result[result.length-1].id })
                  this.actualizaMensajes(mensajes)
                }
                showLoader(false);
              } else if (result.Caducada !== undefined) {
                this.props.history.push({
                  pathname: '/login',
                  search: '',
                  state: { caducada: true, url: this.props.location.pathname}
                });
              }else{
                showLoader(false);
                this.muestraError(result.Error)
              }
          })
      }
    }

    actualizaUsuarios = () =>{
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Mensajeria?action=obtieneUsuariosDisponibles&token=" + ses.token)
        .then(res2 => res2.json())
        .then((result2) => {
          if (result2.Error === undefined) {
            if(result2.length > 0){
              this.setState({usuarios: result2 })
              this.compruebaUsuarioDestino(result2)
            }
          } else if (result2.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            this.muestraError(result2.Error)
          }
          if(this.state.destinoid === undefined){
            showLoader(false);
          }
      })
    }

    compruebaUsuarioDestino = (usuarios) =>{
      if(this.state.destinoid !== undefined && this.state.destino !== 0){
          const usu = usuarios.find((u)=>u.id === this.state.destinoid)
          if(usu === undefined){
              this.setState({destino: 0 })
              this.muestraError('No tienes permisos para hablar con ese usuario o no existe.')
          }
      }
    }

    componentDidMount(){
      if(this.state.destinoid !== undefined){
        this.actualizaChat(this.state.destinoid)
        if(this.state.destino !== 0){
          setTimeout(()=>{
            this.intervalID = setInterval(()=>{this.actualizaChat(this.state.destinoid)},30000)
          },10000)
        }
      }
      this.actualizaUsuarios()
      this.props.setBreadcrumbItems('Mensajes', this.state.breadcrumbItems);
    }

    componentWillUnmount(){
      if(this.intervalID!==undefined){
        clearInterval(this.intervalID);
      }
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    render() {
        const ses = getLoggedInUser()
        const usuarios = this.state.usuarios
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                    <Col md="9" lg="9" xl="9">
                      {this.state.destinoid !== undefined && this.state.destinoid !== 0 &&
                        <ChatBox
                          onSendMessage={this.handleOnSendMessage}
                          userId={ses.nombre}
                          messages={this.state.mensajes}
                          timestampFormat={"D-MM-yyyy H:mm:ss"}
                          showTypingIndicator={false}
                        />
                      }
                    </Col>

                    <Col md="3" lg="3" xl="3">
                      <Card>
                          <CardHeader>
                            <h5 className="mb-0"><i className="dripicons-mail"></i> Usuarios disponibles</h5>
                          </CardHeader>
                          <CardText className="list-group list-line">
                                {!isEmpty(usuarios) &&
                                  usuarios.map((u,id)=>{
                                    if(this.state.destinoid === u.id){
                                        return <Link key={id} to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} {u.usuario}<br/><small>({u.perfil}) - {u.grupos}</small></Link>
                                    }else{
                                        return <Link key={id}  onClick={() => {this.props.history.push("/mensajes/"+u.id); window.location.reload();}} to={"/mensajes/"+u.id} className="list-group-item list-line">{u.usuario}<br/><small>({u.perfil}) - {u.grupos}</small></Link>
                                    }
                                  })
                                }
                          </CardText>
                      </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Chat));
