import React, { Component } from 'react';
import {  Col,   Row, Card , CardBody, Alert, Collapse, Table } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CONFIG from '../../CONFIG.json';
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty } from '../../auxiliares/funciones';

import Lateral from './lateral';

import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";


class Curso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Inicio", link : "/app" },
                { title : "Cursos", link : "/cursos" },
                { title : "", link : "/curso/"+this.props.match.params.id }
            ],
            id: this.props.match.params.id,
            curso:{},
            contenido:[],
            detalle:[],
            visible:-1
        }

        this.props.history.listen((location, action) => {
          if(location.pathname.split('/').length === 3){
            const idN = location.pathname.split('/')[2]
            if(this.state.id !== idN && location.pathname === '/curso/'+idN){
              this.setState({id:idN})
              showLoader(true);
              this.consultaCurso(idN)
            }
          }
        });
    }

    consultaCurso = (id) =>{
      const action = getProfile() === 4 ? 'grupo' : 'curso'
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Cursos?action=cursopor"+action+"&token=" + ses.token+"&id="+id)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            if(result.matriculado === '0'){
              this.setState({error:'No existe ningun curso con ese valor' ,error_dlg:true})
              workWithLocalStorage('remove','course',{})
              showLoader(false);
            }else{
              workWithLocalStorage('set','course',result)
              let bread = this.state.breadcrumbItems
              bread[2].title = result.nombrecorto
              this.setState({breadcrumbItems: bread})
              this.props.setBreadcrumbItems(result.nombre, this.state.breadcrumbItems);
              this.setState({curso:result})
              fetch(CONFIG.api + "Cursos?action=contenidopor"+action+"&token=" + ses.token+"&id="+id)
                .then(res2 => res2.json())
                .then((result2) => {
                  if (result2.Error === undefined) {
                    fetch(CONFIG.api + "Cursos?action=contenidodetallepor"+action+"&token=" + ses.token+"&id="+id)
                      .then(res3 => res3.json())
                      .then((result3) => {
                        if (result3.Error === undefined) {
                          const detalle = result2.map((r,id)=>{
                            return result3.filter(res => res.contenidoid === r.id)
                          })
                          this.setState({contenido:result2 , detalle:detalle})
                        } else if (result3.Caducada !== undefined) {
                          this.props.history.push({
                            pathname: '/login',
                            search: '',
                            state: { caducada: true, url: this.props.location.pathname} 
                          });
                        }else{
                          showLoader(false);
                          this.setState({error:result3.Error ,error_dlg:true})
                        }
                      })
                  } else if (result2.Caducada !== undefined) {
                    this.props.history.push({
                      pathname: '/login',
                      search: '',
                      state: { caducada: true, url: this.props.location.pathname} 
                    });
                  }else{
                    showLoader(false);
                    this.setState({error:result2.Error ,error_dlg:true})
                  }
                })
              }
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            showLoader(false);
            this.setState({error:result.Error ,error_dlg:true, curso:{}})
          }
        })
    }

    componentDidMount(){
        showLoader(true);
        this.consultaCurso(this.props.match.params.id)
    }

    toggle = (id) =>{
        this.setState({visible: this.state.visible === id ? -1 : id})
    }

    render() {
        if(!isEmpty(this.state.contenido)){
          showLoader(false);
        }
        return (
            <React.Fragment>
                <Row>
                    <Col md="12" lg="12" xl="12">
                    { !isEmpty(this.state.curso) && getProfile() === 4 && <Alert color="info"><i className="dripicons-information"></i> Tu curso comenzó el día <strong>{this.state.curso.fechainicio}</strong> y terminará el día <strong>{this.state.curso.fechafin}</strong></Alert>}
                    {this.state.error_dlg &&<Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>}
                    </Col>
                    <Col md="9" lg="9" xl="9">
                    {!isEmpty(this.state.contenido) &&
                    <Card>
                        <CardBody>
                          <div id="accordion">
                            {this.state.contenido.map((c,id)=>{
                              let icon = c.tipo === 'examen' ? 'dripicons-blog' : c.tipo === 'actividad' ? 'dripicons-document-edit' : 'dripicons-view-list'
                              let backUnit = c.tipo === 'unidad' ? (c.avancetotal === c.avance ? "dripicons-checkmark" : '')
                              : c.tipo === 'examen' ? (c.nota>=(c.puntuacionmax/2) ? "dripicons-checkmark" : '')
                              : c.tipo === 'actividad' ?  (c.nota>=5 ? "dripicons-checkmark" : '')
                              : ''
                              return <div className="card mb-1 shadow-none" key={id}>
                                      <Link to="#" className="text-dark" onClick={()=>this.toggle(id)} style={{ cursor : "pointer" }}>
                                          <div className={"card-header p-3 "}>
                                              <h6 className="m-0 font-size-14">
                                                    <i className={icon} style={{paddingRight:'15px'}}></i>{backUnit !== '' &&<i className={backUnit} style={{paddingRight:'15px',color:'green'}}></i>} {c.titulo}
                                              </h6>
                                          </div>
                                        </Link>
                                        <Collapse isOpen={this.state.visible === id}>
                                            <CardBody>
                                              <div className="table-responsive">
                                                {c.tipo === 'unidad' && this.state.detalle[id].length > 0 ?
                                                  <Table className="table table-striped mb-0">
                                                      <thead>
                                                          <tr>
                                                              <th>Visto</th>
                                                              <th>Nombre</th>
                                                              <th>Acción</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                      {this.state.detalle[id][0].mapaconceptualid !== '' &&
                                                      <tr>
                                                          <th scope="row"></th>
                                                          <td>Mapa conceptual</td>
                                                          <td>
                                                            <Link to={'/curso/'+this.props.match.params.id+"/contenido/"+c.id+'/mapa-conceptual'} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link>
                                                          </td>
                                                      </tr>
                                                      }
                                                      {this.state.detalle[id][0].objetivos !== '' &&
                                                      <tr>
                                                          <th scope="row"></th>
                                                          <td>Objetivos</td>
                                                          <td>
                                                            <Link to={'/curso/'+this.props.match.params.id+"/contenido/"+c.id+'/objetivos'} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link>
                                                          </td>
                                                      </tr>
                                                      }
                                                      {  // eslint-disable-next-line
                                                        this.state.detalle[id].map((d,id2)=>{
                                                          if(id2 === 0 || d.titulo !== this.state.detalle[id][id2-1].titulo){
                                                            const visto = d.avance === d.avancetotal ? 'dripicons-checkmark' : ''
                                                            return <tr key={d.id}>
                                                                      <th style={{textAlign: 'center'}} scope="row"><i className={visto} style={{paddingRight:'15px',color:'green'}}></i></th>
                                                                      <td>{d.titulo}</td>
                                                                      <td>
                                                                        <Link to={"/curso/"+this.props.match.params.id+"/contenido/"+c.id+"/detalle/"+d.id} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link>
                                                                      </td>
                                                                  </tr>
                                                          }
                                                        })
                                                      }
                                                      {this.state.detalle[id][0].resumen !== '' &&
                                                      <tr>
                                                          <th scope="row"></th>
                                                          <td>Resumen</td>
                                                          <td>
                                                            <Link to={'/curso/'+this.props.match.params.id+"/contenido/"+c.id+'/resumen'} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link>
                                                          </td>
                                                      </tr>
                                                      }
                                                      {this.state.detalle[id][0].glosario !== '' &&
                                                      <tr>
                                                          <th scope="row"></th>
                                                          <td>Glosario</td>
                                                          <td>
                                                            <Link to={'/curso/'+this.props.match.params.id+"/contenido/"+c.id+'/glosario'} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link>
                                                          </td>
                                                      </tr>
                                                      }
                                                      {this.state.detalle[id][0].bibliografia !== '' &&
                                                      <tr>
                                                          <th scope="row"></th>
                                                          <td>Bibliografía</td>
                                                          <td>
                                                            <Link to={'/curso/'+this.props.match.params.id+"/contenido/"+c.id+'/bibliografia'} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link>
                                                          </td>
                                                      </tr>
                                                      }
                                                      </tbody>
                                                    </Table>
                                                      : c.tipo === 'examen' ?
                                                    <Table className="table table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Nº Intentos</th>
                                                                <th>Ult. Intentos</th>
                                                                <th>Mejor Nota</th>
                                                                <th>Acción</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">{(c.nota >= c.puntuacionmax/2) && <i className="dripicons-checkmark" style={{paddingRight:'15px',color:'green'}}></i>}</th>
                                                                <td>{c.intentos} de {c.intentosmax}</td>
                                                                <td>{c.ultintento}</td>
                                                                <td>{c.intentos > 0 && c.nota+"/"+c.puntuacionmax}</td>
                                                                <td><Link to={'/curso/'+this.props.match.params.id+"/examen/"+c.examenid} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link></td>
                                                            </tr>
                                                        </tbody>
                                                      </Table>
                                                        : c.tipo === 'actividad' &&
                                                      <Table className="table table-striped mb-0">
                                                          <thead>
                                                              <tr>
                                                                  <th></th>
                                                                  <th>Estado</th>
                                                                  <th>Nota</th>
                                                                  <th>Acción</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr>
                                                                  <th scope="row">{(c.notaactividad !== '' && c.notaactividad >= 5) && <i className="dripicons-checkmark" style={{paddingRight:'15px',color:'green'}}></i>}</th>
                                                                  <td>{c.estadoactividad === '' ? 'Pendiente' : c.estadoactividad}</td>
                                                                  <td>{c.notaactividad}</td>
                                                                  <td><Link to={'/curso/'+this.props.match.params.id+"/actividad/"+c.actividadid} className="mr-1 btn btn-primary waves-effect waves-light">Ver</Link></td>
                                                              </tr>
                                                          </tbody>
                                                        </Table>
                                                    }
                                              </div>
                                            </CardBody>
                                        </Collapse>
                                    </div>
                              })}
                            </div>
                          </CardBody>
                      </Card>
                      }
                    </Col>
                    <Col md="3" lg="3" xl="3">
                        {!isEmpty(this.state.curso) && <Lateral id={this.props.match.params.id}/>}
                    </Col>
                </Row>

                </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Curso));
