import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button, Form, FormGroup, Label, Input, CardText } from 'reactstrap';
import { connect } from "react-redux";
import CONFIG from '../../../CONFIG.json';
import { Link, withRouter } from "react-router-dom";
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty } from '../../../auxiliares/funciones';

import SweetAlert from "react-bootstrap-sweetalert";
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../auxiliares/breadcrumb/actions";


class Texto extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "", link : "/curso/"+this.props.match.params.id+"/examen/"+this.props.match.params.id2},
          ],
          muestraMsg:false,
          course : course,
          examen: {},
          intentos: [],
          intento: 0,
          form:{},
          corregido: false,
          error:'',
          error_dlg: false,
          hechoIntentos:false
        }
    }

    componentDidMount(){
      this.cargaExamen(0)
    }

    cargaExamen = (intento) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      const grupoid = getProfile() === 4 ? this.props.match.params.id : 'null'
      fetch(CONFIG.api + "Examen?action=obtieneExamen&token=" + ses.token+"&ejercicioid="+this.props.match.params.id2+"&intentoid="+intento+"&grupoid="+grupoid)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            let bread = this.state.breadcrumbItems
            bread[3].title = result.campos.nombre
            this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
            if(getProfile() === 4){
              this.cargaIntentos(result)
              intento !== 0 && this.rellenaFormIntento(result)
              intento !== 0 && this.setState({intento: intento})
            }else{
              this.setState({examen:result})
              showLoader(false);
            }
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            showLoader(false);
            this.muestraError(result.Error)
          }
      })
    }

    rellenaFormIntento = (examen) =>{
      let form = {} // eslint-disable-next-line
      examen.preguntas.map((p)=>{
          const s = p.respuestas.find(e => e.seleccionado === "1")
          form[p.campos.id] = s.id
      })
      this.setState({form: form, corregido:true})
    }

    resetExamen = () =>{
      let examen = this.state.examen
      examen.preguntas.forEach((iteme, e) => {
        examen.preguntas[e].respuestas.forEach((item, i) => {
          examen.preguntas[e].respuestas[i].seleccionado = 0
        });
      });

      this.setState({corregido:false, examen: examen, form:{}, error_dlg: false, nota_dlg: false })
    }

    cargaIntentos = (examen) =>{
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Examen?action=obtieneIntentos&token=" + ses.token+"&ejercicioid="+this.props.match.params.id2+"&grupoid="+this.props.match.params.id)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              const hechoIntentos = result.length === parseInt(examen.campos.intentosmax) ? true : false
              this.setState({examen:examen,hechoIntentos: hechoIntentos, intentos:result})
              showLoader(false);
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname} 
              });
            }else{
              this.muestraError(result.Error)
            }
        })
    }

    corregirEjercicio = (e) =>{
      e.preventDefault();
      if(this.state.form.length < this.state.examen.preguntas.length){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Faltan respuestas",texto:'Debes responder a todas las preguntas'}})
      }else{
        if(getProfile() === 4){
            const exa = { id : this.props.match.params.id2,
                      grupoid: this.props.match.params.id,
                      form: this.state.form
                    }
            showLoader(true);
            const ses = getLoggedInUser()
            let form = Object.keys(exa.form).map(k => k + '=' + exa.form[k]).join(':');
            fetch(CONFIG.api + "Examen?token="+ses.token+"&action=registraExamen&id="+exa.id+"&grupoid="+exa.grupoid+"&examen=" + form)
              .then(res => res.json())
              .then((result) => {
                window.scrollTo(0, 0);
                if (result.Error === undefined) {
                  this.setState({corregido:true})
                  const nota = 'Has obtenido una puntuacion de '+result.nota+' sobre '+result.max
                  if(result.resultado === 'aprobado'){
                    this.muestraNota(nota+'. ¡Buen trabajo!')
                  }else{
                    this.muestraError(nota+'. Revisa tus respuestas')
                  }
                  this.cargaExamen(result.intento)
                } else if (result.Caducada !== undefined) {
                  this.props.history.push({
                    pathname: '/login',
                    search: '',
                    state: { caducada: true, url: this.props.location.pathname} 
                  });
                }else{
                  showLoader(false);
                  this.muestraError(result.Error)
                }
            })
        }else{
          this.setState({corregido:true})
        }
      }
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }
    muestraNota = (text) =>{
      this.setState({nota:text ,nota_dlg:true})
    }

    actualizaValoresForm = (e,id) =>{
      const value = e.target.value;
      let form = this.state.form
      form[id]=value
      this.setState({form: form, corregido:false})
    }

    render() {
        const perfil = getProfile()
        const examen = this.state.examen
        const intentos = this.state.intentos
        const form = this.state.form
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {this.state.nota_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="success"><i className="dripicons-checkmark"></i> {this.state.nota}</Alert>
                    </Col>
                }
                {!isEmpty(examen) &&
                    <Col md="8" lg="8" xl="8">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">{examen.campos.nombre}</h4>
                            </CardHeader>
                            <CardBody>
                            <Form className="examForm" style={{paddingLeft: '15px',paddingRight: '15px'}} onSubmit={this.corregirEjercicio}>
                              {this.state.muestraMsg &&
                                <SweetAlert
                                type={this.state.msg.tipo}
                                title={this.state.msg.titulo}
                                onConfirm={() => this.setState({ muestraMsg: false })}
                                >
                                {this.state.msg.texto}
                                </SweetAlert>
                              }
                              {examen.preguntas.map((p,id)=>{
                                return (<FormGroup tag="fieldset" key={'p'+id}>
                                          <h5>{p.campos.pregunta}</h5>
                                          {p.respuestas.map((r,id2)=>{
                                            return  (<FormGroup check key={'r'+id2}>
                                                      <Label check>
                                                        <Input type="radio" value={r.id} disabled={this.state.corregido} checked={r.seleccionado === "1" || form[parseInt(p.campos.id)] === r.id ? true : false} onChange={(e)=>this.actualizaValoresForm(e,p.campos.id)} name={id} />{' '}{r.respuesta}
                                                        {(this.state.corregido && r.valor === '1') && <i style={{color:'green'}} className="dripicons-checkmark"></i>}
                                                        {(this.state.corregido && form[parseInt(p.campos.id)] === r.id && r.valor === '0') && <i style={{color:'red'}} className="dripicons-cross"></i>}
                                                      </Label>
                                                    </FormGroup>)
                                          })}
                                        </FormGroup>
                                        )
                              })}
                              {(this.state.corregido || this.state.hechoIntentos || getProfile() === 2 || getProfile() === 3) ? <Button type="button" color="secondary" style={{cursor: 'not-allowed'}} className="waves-effect" disabled>Corregir</Button>
                              : <Button type="submit" color="success" className="waves-effect">Corregir</Button>
                              }
                            </Form>
                            </CardBody>
                        </Card>
                    </Col>
                  }

                    <Col md="4" lg="4" xl="4">
                      <Card>
                        {!isEmpty(examen) && perfil === 4 &&
                          <CardHeader>
                            <h4 className="mb-0">Intentos</h4>
                            <h6 className="mb-0">Nº Max: {examen.campos.intentosmax}</h6>
                          </CardHeader>
                        }
                        {!isEmpty(examen) && perfil === 4 &&
                          <CardText className="list-group list-line">
                            { intentos.map((c,id)=>{
                                if(this.state.intento === parseInt(c.id)){
                                    return <Link key={id} to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} Intento {c.intento} ({c.nota}/{c.max}): {c.fecha}</Link>
                                }else{
                                    return <Link key={id} to={"/curso/"+this.props.match.params.id+"/examen/"+this.props.match.params.id2} onClick={() => this.cargaExamen(parseInt(c.id))} className="list-group-item list-line">Intento {c.intento} ({c.nota}/{c.max}): {c.fecha}</Link>
                                }
                            })}
                          </CardText>
                        }
                        {!isEmpty(examen) && perfil === 4 && !this.state.hechoIntentos && this.state.corregido &&
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id+"/examen/"+this.props.match.params.id2} onClick={() => this.resetExamen()} className="btn btn-warning waves-effect waves-light">Volver a probar</Link>
                          </CardBody>
                        }
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Texto));
