import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardText} from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class ListContenido extends Component {

    render() {
        const contenido = this.props.contenido
        const did = this.props.detalleid
        return (
                <Card>
                    <CardHeader>
                      <h4 className="mb-0">Contenido</h4>
                    </CardHeader>
                    <CardBody>
                        <Link to={"/curso/"+this.props.grupoid} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                    </CardBody>
                    <CardText className="list-group list-line">
                      {contenido[0].mapaconceptualid !== '' ?
                      did === 'mapa-conceptual' ?<Link key="mapa" to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} Mapa Conceptual</Link>
                      : <Link key="mapa" onClick={() => this.props.getDetalle('mapa-conceptual')} to={"/curso/"+this.props.grupoid+"/contenido/"+contenido[0].contenidoid+"/mapa-conceptual"} className="list-group-item list-line">Mapa Conceptual</Link>
                      : null}
                      {contenido[0].objetivos !== '' ?
                      did === 'mapa-conceptual' ? <Link key="Objetivos" to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} Objetivos</Link>
                      : <Link key="Objetivos" onClick={() => this.props.getDetalle('objetivos')} to={"/curso/"+this.props.grupoid+"/contenido/"+contenido[0].contenidoid+"/objetivos"} className="list-group-item list-line">Objetivos</Link>
                      : null}
                      {  // eslint-disable-next-line
                        contenido.map((c,id)=>{
                        if(id === 0 || c.titulo !== contenido[id-1].titulo){
                          if(this.props.titulo === c.titulo){
                              return <Link key={id} to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} {c.titulo}</Link>
                          }else{
                              return <Link key={id}  onClick={() => this.props.getDetalle(c.id)} to={"/curso/"+this.props.grupoid+"/contenido/"+c.contenidoid+"/detalle/"+c.id} className="list-group-item list-line">{c.titulo}</Link>
                          }
                        }
                      })}
                      {contenido[0].resumen !== '' ?
                      did === 'resumen' ? <Link key="resumen" to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} Resumen</Link>
                      : <Link key="resumen" onClick={() => this.props.getDetalle('resumen')} to={"/curso/"+this.props.grupoid+"/contenido/"+contenido[0].contenidoid+"/resumen"} className="list-group-item list-line">Resumen</Link>
                      : null}
                      {contenido[0].glosario !== '' ?
                      did === 'glosario' ? <Link key="glosario" to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} Glosario</Link>
                      : <Link key="glosario" onClick={() => this.props.getDetalle('glosario')} to={"/curso/"+this.props.grupoid+"/contenido/"+contenido[0].contenidoid+"/glosario"} className="list-group-item list-line">Glosario</Link>
                      : null}
                      {contenido[0].bibliografia !== '' ?
                      did === 'bibliografia' ? <Link key="biblio" to={''} className="list-group-item list-line disabled bg-primary fontWhite"> {'>'} Bibliografía</Link>
                      : <Link key="biblio" onClick={() => this.props.getDetalle('bibliografia')} to={"/curso/"+this.props.grupoid+"/contenido/"+contenido[0].contenidoid+"/bibliografia"} className="list-group-item list-line">Bibliografía</Link>
                      : null}
                    </CardText>
                    <CardBody>
                        <Link to={"/curso/"+this.props.grupoid} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                    </CardBody>
                </Card>
        );
    }
}

export default connect(null, { })(ListContenido);
