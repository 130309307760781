import React, { Component } from 'react';
import { connect } from "react-redux";
import CONFIG from '../../../CONFIG.json';
import { withRouter } from 'react-router-dom';
import { showLoader, isEmpty } from '../../../auxiliares/funciones';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";


class Ejercicio extends Component {
  constructor(props) {
      super(props);
      this.state = {
        examen: {},
        form:[],
        corregido: false,
        muestraMsg:false
      }
  }

    componentDidMount(){
      showLoader(true);
      fetch(CONFIG.api + "Examen?action=obtieneExamen&token=" + this.props.sesion.token+"&ejercicioid="+this.props.ejercicioid)
        .then(res => res.json())
        .then((result) => {
          showLoader(false);
          if (result.Error === undefined) {
            this.setState({examen:result})
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            this.props.muestraError(result.Error)
          }
      })
    }

    corregirEjercicio = (e) =>{
      e.preventDefault();
      if(this.state.form.length < this.state.examen.preguntas.length){
        this.setState({muestraMsg:true})
      }else{
        this.setState({corregido:true})
      }
    }

    actualizaValoresForm = (e,id) =>{
      const value = e.target.value;
      let form = this.state.form
      form[id]=value
      this.setState({form: form, corregido:false})
    }

    render() {
      if(!isEmpty(this.state.examen)){
        const preguntas = this.state.examen.preguntas
        const form = this.state.form
        return (
          <Form className="examForm" style={{paddingLeft: '15px',paddingRight: '15px'}} onSubmit={this.corregirEjercicio}>
            {this.state.muestraMsg &&
              <SweetAlert
              warning
              title="Faltan respuestas"
              onConfirm={() => this.setState({ muestraMsg: false })}
              >
              Debes responder a todas las preguntas
              </SweetAlert>
            }
            {preguntas.map((p,id)=>{
              return (<FormGroup tag="fieldset" key={'p'+id}>
                        <h5>{p.campos.pregunta}</h5>
                        {p.respuestas.map((r,id2)=>{
                          return  (<FormGroup check key={'r'+id2}>
                                    <Label check>
                                      <Input type="radio" value={r.id} onChange={(e)=>this.actualizaValoresForm(e,id)} name={id} />{' '}{r.respuesta}
                                      {(this.state.corregido && r.valor === '1') && <i style={{color:'green'}} className="dripicons-checkmark"></i>}
                                      {(this.state.corregido && form[id] === r.id && r.valor === '0') && <i style={{color:'red'}} className="dripicons-cross"></i>}
                                    </Label>
                                  </FormGroup>)
                        })}
                      </FormGroup>
                      )
            })}
            <Button type="submit" color="success" className="waves-effect">Corregir</Button>
          </Form>
        );
      }else{
        return <div></div>
      }
    }
}

export default withRouter(connect(null, { })(Ejercicio));
