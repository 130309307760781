import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody, CardHeader, Button, Form, Modal, ModalHeader, ModalBody,  ModalFooter, FormGroup, Label } from 'reactstrap';
import { connect } from "react-redux";
import CONFIG from '../../CONFIG.json';
import { Link, withRouter } from "react-router-dom";
import { showLoader,getLoggedInUser, workWithLocalStorage, isEmpty, getProfile } from '../../auxiliares/funciones';

import SweetAlert from "react-bootstrap-sweetalert";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";


class Foro extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "", link : "/curso/"+this.props.match.params.id+"/foros"},
              { title : "", link : "/curso/"+this.props.match.params.id+"/foro/"+this.props.match.params.id2+"/"+this.props.match.params.id3},
          ],
          course : course,
          posts:[],
          modal: false,
          foroid:this.props.match.params.id2,
          temaid:this.props.match.params.id3,
          postid:'',
          texto:'',
          error:'',
          error_dlg: false
        }
    }

    abreModal = (postid)=> {
        this.setState({modal: true, postid: postid})
    }

    cierraModal = ()=> {
        this.setState({modal: false, foroid: 0,error_dlg:false})
    }

    rellenaTexto = (texto) =>{
      this.setState({texto: texto})
    }

    postearMensaje = () =>{
      if(this.state.texto === ''){
        this.setState({muestraMsg:true, msg:{tipo:'warning',titulo:"Datos incorrectos",texto:'Debes rellenar el mensaje.'}})
      }else{
        showLoader(true);
        const ses = getLoggedInUser()
        let mensaje = this.state.texto
        mensaje = mensaje.replaceAll('&','%26');
        fetch(CONFIG.api + "Foros?action=anyadirMensaje&token=" + ses.token+"&temaid="+this.state.temaid+"&mensaje="+encodeURI(mensaje)+"&postid="+this.state.postid)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              window.location.reload()
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: window.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
      }
    }

    borraPost = (postid) =>{
        showLoader(true);
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Foros?action=borrarMensaje&token=" + ses.token+"&postid="+postid)
          .then(res => res.json())
          .then((result) => {
            if (result.Error === undefined) {
              window.location.reload()
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: window.location.pathname}
              });
            }else{
              showLoader(false);
              this.muestraError(result.Error)
            }
        })
    }

    componentDidMount(){
      showLoader(true);
      const ses = getLoggedInUser()
      const grupoid = getProfile() === 4 ? this.props.match.params.id : 'null'
      fetch(CONFIG.api + "Foros?action=postportema&token=" + ses.token+"&foroid="+this.state.foroid+"&temaid="+this.state.temaid+"&grupoid="+grupoid)
        .then(res => res.json())
        .then((result) => {
          showLoader(false);
          if (result.Error === undefined) {
            /*const temas = result.map((r,id)=>{
              return result2.filter(res => res.foroid === r.id)
            })*/
            this.setState({posts:result})
            let bread = this.state.breadcrumbItems
            bread[3].title = result[0].foronombre
            bread[4].title = result[0].temanombre
            this.props.setBreadcrumbItems(this.state.course.nombre, bread);
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: window.location.pathname}
            });
          }else{
            this.muestraError(result.Error)
          }
      })
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    render() {
        const posts = this.state.posts
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                {!isEmpty(posts) &&
                    <Col md="9" lg="9" xl="9">
                        <Card>
                            <CardHeader>
                              <h4 className="mb-0">{posts[0].temanombre} <small>({posts[0].foronombre})</small></h4>
                            </CardHeader>
                            {this.state.muestraMsg &&
                              <SweetAlert
                              type={this.state.msg.tipo}
                              title={this.state.msg.titulo}
                              onConfirm={() => this.setState({ muestraMsg: false })}
                              >
                              {this.state.msg.texto}
                              </SweetAlert>
                            }
                            <CardBody>
                              <button type="button" onClick={()=>{this.abreModal('')}} className="btn btn-primary btn-rounded waves-effect waves-light m-t-5">Postear</button>
                              <section id="cd-timeline" className="cd-container" dir="ltr">
                              {posts.map((p,id)=>{
                                const position = p.comentariopadreid===p.id ? 'timeline-left' : 'timeline-right'
                                const classChild = p.comentariopadreid===p.id ? 'foroLeft' : 'foroRight'
                              return <div key={id} className={"cd-timeline-block "+position}>
                                      <div className={"cd-timeline-content width75 "+classChild}>
                                          <h5>{p.usuario}</h5>
                                          <p className="text-muted" dangerouslySetInnerHTML={{ __html: p.mensaje }}></p>
                                          <button type="button" onClick={()=>{this.abreModal(''+p.id)}} style={{marginRight: '20px'}} className="mb-2 btn btn-primary btn-rounded waves-effect waves-light m-t-5">Responder</button>
                                          {(p.borrable === '1' || getProfile() === 2) &&
                                          <button type="button" onClick={()=>{this.borraPost(p.id)}} className="mb-2 btn btn-secondary btn-rounded waves-effect waves-light m-t-5">Borrar</button>
                                          }
                                          <span className="cd-date">{p.fechacreado}</span>
                                      </div>
                                  </div>
                                })}
                              </section>
                            </CardBody>
                            <Modal isOpen={this.state.modal} toggle={this.cierraModal} autoFocus={true} size = "lg">
                                  <ModalHeader toggle={this.cierraModal}>
                                      Nuevo Post
                                  </ModalHeader>
                                  <ModalBody>
                                  <Form method="post">
                                    {this.state.error_dlg &&
                                      <FormGroup row>
                                        <Col md="12" lg="12" xl="12">
                                          <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                                        </Col>
                                      </FormGroup>
                                    }
                                    <FormGroup row>
                                      <Label for="example-text-input" className="col-sm-12 col-form-label">Mensaje</Label>
                                      <Col sm="12">
                                        <SunEditor name="text"
                                            setOptions={{height: 200}}
                                            onChange={this.rellenaTexto}
                                        />
                                      </Col>
                                    </FormGroup>
                                    </Form>
                                  </ModalBody>
                                  <ModalFooter>
                                      <Button type="button" color="secondary" className="waves-effect" onClick={()=>{this.cierraModal()}}>Cancelar</Button>
                                      <Button type="button" color="primary" className="waves-effect waves-light" onClick={()=>{this.postearMensaje()}}>Postear</Button>
                                  </ModalFooter>
                            </Modal>
                        </Card>
                    </Col>
                  }

                    <Col md="3" lg="3" xl="3">
                      <Card>
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id+"/foros"}>Volver al listado de foros</Link>
                          </CardBody>
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Foro));
