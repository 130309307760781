import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, Media } from 'reactstrap';
import CONFIG from '../CONFIG.json';
import { getLoggedInUser, showLoader } from '../auxiliares/funciones';
import { getTimeFromSeconds } from '../auxiliares/dates';

//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class NotificationMenu extends Component {
    intervalID = 0;
    constructor(props) {
        super(props);
        this.state = {
            notifications : [],
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount(){
      this.refreshNotifications()
      this.intervalID = setInterval(()=>{this.refreshNotifications()},300000)
    }

    componentWillUnmount(){
      clearInterval(this.intervalID);
    }

    refreshNotifications = () =>{
      const user = getLoggedInUser()
      fetch(CONFIG.api + "Usuario?action=actualizaNotificacionesReact&token=" + user.token)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            const nots = result.map((r)=>{
              const icon = r.controlador === 'Mensaje' ? 'dripicons-mail' : r.controlador === 'Curso' ? 'dripicons-checklist' : r.controlador === 'Foro' ? 'dripicons-network-3' : 'dripicons-information'
              let path = r.path
              if(r.controlador === 'Actividad'){
                path = '/curso/'+path.split('=')[2]+'/actividad/'+path.split('=')[1].split('&')[0]
              }
              return { id : r.id,path : path, icon : icon, title : r.controlador, desc : r.descripcion, time: getTimeFromSeconds(r.hacetiempo) }
            })
            this.setState({notifications:nots})
          }
        })
    }

    checkAsReaded = () => {
      showLoader(true)
      const user = getLoggedInUser()
      fetch(CONFIG.api + "Usuario?action=marcarLeidoNotificaciones&token=" + user.token)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            this.toggle()
            this.setState({notifications:[]})
            showLoader(false)
          }
        })
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {
        return (
            <React.Fragment>
                    <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block ml-1">
                        <DropdownToggle
                            className="btn header-item noti-icon waves-effect"
                            tag="button"
                            id="page-header-notifications-dropdown"
                        >
                        <i className={"dripicons-bell"}></i>
                        <span className="badge badge-danger badge-pill">{this.state.notifications.length}</span>
                        </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-lg p-0">
                                <div className="p-3">
                                    <Row className="align-items-center">
                                        <Col>
                                            <h5 className="m-0"> Notificaciones ({this.state.notifications.length}) </h5>
                                        </Col>
                                    </Row>
                                </div>
                                <PerfectScrollbar style={{height : "230px"}}>
                                    {
                                        this.state.notifications.map((notification, key) =>
                                            <Link key={key} to={notification.path} className="text-reset notification-item">
                                                <Media className="borderTop">
                                                    <div className="avatar-xs mr-3">
                                                        <span className="avatar-title rounded-circle ">
                                                            <i className={notification.icon}></i>
                                                        </span>
                                                    </div>
                                                    <Media body>
                                                        <h6 className="mt-0 mb-1">{notification.title} <small>{notification.time}</small></h6>
                                                        <div className="text-muted">
                                                            <p className="mb-1">{notification.desc}</p>
                                                        </div>
                                                    </Media>
                                                </Media>
                                            </Link>
                                        )
                                    }
                            </PerfectScrollbar>
                            <div className="p-2 border-top">
                                <Link onClick={()=>{this.checkAsReaded()}} className="btn btn-sm btn-link font-size-14 btn-block text-center" to="#">
                                    Marcar como leídas
                                </Link>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
            </React.Fragment>
        );
    }
}

export default NotificationMenu;
