import React, { Component } from 'react';
import {  Table, Alert,  FormGroup, Label, Input, PaginationItem, PaginationLink, Button } from "reactstrap";
import { isEmpty, showLoader } from './funciones';

class RTabla extends Component {
    constructor(props) {
        super(props);
        this.state = {
          listado: this.props.lista ? this.props.lista : [],
          listadoO: this.props.lista ? this.props.lista : [],
          buscable: this.props.buscable ? this.props.buscable : [],
          cabecera: this.props.cabecera ? this.props.cabecera : [],
          campos: this.props.campos ? this.props.campos : [],
          paginable: this.props.paginable ? this.props.paginable : false,
          loader: this.props.loader ? this.props.loader : false,
          scroll: this.props.scroll ? this.props.scroll : false,
          acciones: this.props.acciones ? this.props.acciones : [],
          resultados: 10,
          mostrando:0,
          paginas: this.calculaPaginas(this.props.lista),
          paginacionN:this.paginacionInicial(this.calculaPaginas(this.props.lista))
        }
    }

    calculaPaginas = (datos) =>{
      let pagis = datos ? datos.length / 10 : 0
      pagis = pagis % 1 !== 0 ? parseInt(pagis)+1 : pagis
      return pagis
    }

    paginacionInicial = (paginas) =>{
      let pags = [1,2,3,4,5]
      while(paginas<pags.length){
        pags.pop();
      }
      return pags
    }

    buscaTabla = (input,tabla) =>{
        const datos = this.state.listadoO.filter(item => {
          return Object.keys(item).some(key =>
            item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        const pagis = this.calculaPaginas(datos)
        this.setState({listado: datos,paginas:pagis})
        this.paginacion(0,pagis)
    }

    paginacion = (pag,paginas) =>{
      let pags = []
      if(pag<3){
        pags = [1,2,3,4,5]
        while(paginas<pags.length){
          pags.pop();
        }
      }else if(pag>paginas-3){
        pags = [paginas-4,paginas-3,paginas-2,paginas-1,paginas]
      }else{
        pags = [pag-1,pag,pag+1,pag+2,pag+3]
      }
      this.setState({paginacionN:pags, mostrando:pag})
    }

    componentDidMount(){
      if(this.state.loader){
        showLoader(false)
      }
      if(this.state.scroll){
        window.scrollTo(0,280);
      }
    }

    componentDidUpdate(){
      if(this.state.loader){
        showLoader(false)
      }
      if(this.state.scroll){
        //window.scrollTo(0,280);
      }
    }

    render() {
        const { buscable, paginacionN, cabecera, campos, listado, listadoO, resultados, paginable, acciones} = this.state
        const ini = resultados*this.state.mostrando
        let fin = resultados*(this.state.mostrando+1)
        fin = fin > listado.length ? listado.length : fin

        return (
          <React.Fragment>
              <div className="table-responsive mt-4 RTabla">
                  {buscable &&
                  <FormGroup>
                      <Label className="control-label">Buscar</Label>
                      <Input className="form-control width20" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e,'eventos')} type="text" name="buscar"/>
                  </FormGroup>
                  }
                  {isEmpty(listado) && !isEmpty(listadoO) && <Alert color="warning"><i className="dripicons-information"></i> No hay ningun resultado con ese filtro.</Alert>}
                  <Table className="table table-hover mb-0 table-bordered">
                      <thead className="thead-light">
                          <tr>
                              {acciones !== [] &&<th style={{textAlign: 'center'}}>{' '}</th> }
                              {cabecera.map((c,id)=>{
                                  return <th key={id} style={{textAlign: 'center'}}>{c}</th>
                              })}
                          </tr>
                      </thead>
                      <tbody>
                        { //eslint-disable-next-line
                          listado.map((g,id)=>{
                            if(!paginable || (id>=ini && id<fin)){
                              return <tr key={id}>
                                          {acciones !== [] && // eslint-disable-next-line
                                            <td>
                                             {acciones.map((a, id3)=>{
                                                // eslint-disable-next-line no-unused-expressions
                                                if(a.condition === null || a.condition(g)){
                                                  // eslint-disable-next-line no-unused-expressions
                                                  return <Button variant="contained" sx={a.estilo} onClick={()=> {a.funcion !== null ? a.funcion(g) : undefined}} color={a.color} key={id3}>{a.icono}{a.nombre}</Button>
                                                }
                                              }
                                            )}
                                            </td>
                                          }
                                          {campos.map((c,id2)=>{
                                            return <td key={id2}>{g[c]}</td>
                                          })}
                                      </tr>
                            }
                        })}
                      </tbody>
                      {paginable ? <caption>Mostrando {ini+1} hasta {fin} de {listadoO.length} resultados</caption> : <caption>Mostrando {listadoO.length} resultados</caption>}
                    </Table>
                    {paginable &&
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center mt-4">
                            <PaginationItem disabled={this.state.mostrando === 0}><PaginationLink href={void(0)} onClick={()=>{this.paginacion(this.state.mostrando-1,this.state.paginas)}}>{"<"} Anterior</PaginationLink></PaginationItem>
                            {paginacionN.map((p,id)=>{
                            return <PaginationItem key={id} active={this.state.mostrando === p-1}><PaginationLink href={void(0)} onClick={()=>{this.paginacion(p-1,this.state.paginas)}}>{p}</PaginationLink></PaginationItem>
                            })}
                            <PaginationItem disabled={this.state.mostrando === this.state.paginas-1}><PaginationLink href={void(0)} onClick={()=>{this.paginacion(this.state.mostrando+1,this.state.paginas)}}>Siguiente {">"}</PaginationLink></PaginationItem>
                        </ul>
                    </nav>
                    }
                </div>
              </React.Fragment>
        );
    }
}

export default RTabla;
