import React, { Component } from 'react';
import { Alert, Col, Row, Card, CardBody } from 'reactstrap';
import { connect } from "react-redux";
import CONFIG from '../../CONFIG.json';
import { Link, withRouter } from "react-router-dom";
import { showLoader,getLoggedInUser, getProfile, workWithLocalStorage, isEmpty, compareArrays } from '../../auxiliares/funciones';

import ChatBox from 'react-chat-plugin';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

class Chat extends Component {
    constructor(props) {
        const course = workWithLocalStorage('get','course','')
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Cursos", link : "/cursos" },
              { title : course.nombrecorto, link : "/curso/"+this.props.match.params.id },
              { title : "Chat", link : "/curso/"+this.props.match.params.id+"/chat/"+this.props.match.params.id2}
          ],
          course : course,
          chatid: this.props.match.params.id2,
          usuarios:[],
          texto:'',
          error:'',
          intervalID: 0,
          error_dlg: false,
          mensajes: [],
          ultimomensajeid:0
        }
    }

    handleOnSendMessage = (message) => {
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Chat?action=enviarMensaje&token=" + ses.token+"&chatid="+this.state.chatid+"&mensaje="+message)
          .then(res2 => res2.json())
          .then((result2) => {
            if (result2.Error === undefined) {
              const time = +new Date()
            const mes = {
              author: {
                username: ses.nombre,
                id: ses.nombre,
                avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
              },
              text: message,
              type: 'text',
              timestamp: time,
            }
            this.actualizaMensajes([mes])
          } else if (result2.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            this.muestraError(result2.Error)
          }
      })
  }

    actualizaMensajes = (mensajes) =>{
      let mess = this.state.mensajes
      mensajes.forEach(m => {
        const ind = mess.findIndex(e => e.author.id === m.author.id && e.text === m.text)
        ind<0 && mess.push(m)
      });
      mess.length !== this.state.mensajes.length && this.setState({mensajes: mess})
    }

    actualizaChat = (inicial) =>{
      const ses = getLoggedInUser()
      const grupoid = getProfile() === 4 ? this.props.match.params.id : 'null'
      fetch(CONFIG.api + "Chat?action=obtieneUltimosMensajes&token=" + ses.token+"&chatid="+this.state.chatid+"&ultimomensajeid="+this.state.ultimomensajeid+"&id="+this.props.match.params.id+"&inicial="+inicial+"&grupoid="+grupoid)
        .then(res => res.json())
        .then((result) => {
          if (result.Error === undefined) {
            fetch(CONFIG.api + "Chat?action=obtieneUsuariosConectados&token=" + ses.token+"&chatid="+this.state.chatid)
              .then(res2 => res2.json())
              .then((result2) => {
                if (result2.Error === undefined) {
                  if(result.length > 0){
                    const mensajes = result.map((m,id)=>{
                      return {
                        author: {
                          username: m.perfil === 'Tutor' ? m.usuario+' (Tutor)' : m.usuario,
                          id: m.usuario,
                          avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
                        },
                        text: m.mensaje,
                        type: 'text',
                        timestamp: parseInt(m.fecha+'000'),
                      }
                    })
                    this.setState({ultimomensajeid: result[result.length-1].id })
                    this.actualizaMensajes(mensajes)
                  }
                  !compareArrays(result2,this.state.usuarios) && this.setState({usuarios:result2})
                  showLoader(false);
                } else if (result2.Caducada !== undefined) {
                  this.props.history.push({
                    pathname: '/login',
                    search: '',
                    state: { caducada: true, url: this.props.location.pathname} 
                  });
                }else{
                  this.muestraError(result2.Error)
                }
            })
          } else if (result.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname} 
            });
          }else{
            showLoader(false);
            this.muestraError(result.Error)
          }
      })
    }

    componentDidMount(){
      this.props.setBreadcrumbItems(this.state.course.nombre, this.state.breadcrumbItems);
      showLoader(true);
      this.actualizaChat(1)
      setTimeout(()=>{
        this.intervalID = setInterval(()=>{this.actualizaChat(0)},3000)
      },3000)

    }

    componentWillUnmount(){
      clearInterval(this.intervalID);
    }

    muestraError = (text) =>{
      this.setState({error:text ,error_dlg:true})
    }

    render() {
        const ses = getLoggedInUser()
        const usuarios = this.state.usuarios
        return (
              <React.Fragment>
                <Row>
                {this.state.error_dlg &&
                    <Col md="12" lg="12" xl="12">
                      <Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>
                    </Col>
                }
                    <Col md="9" lg="9" xl="9">
                        <ChatBox
                          onSendMessage={this.handleOnSendMessage}
                          userId={ses.nombre}
                          messages={this.state.mensajes}
                          timestampFormat={"H:mm:ss"}
                          showTypingIndicator={false}
                        />
                    </Col>

                    <Col md="3" lg="3" xl="3">
                      <Card>
                          {!isEmpty(usuarios) &&
                          <CardBody>
                            <ul className="list-group mini-stat list-group-flush">
                                <li className="list-group-item mini-stat-img">
                                  <i className="dripicons-conversation"></i> Usuarios conectados
                                </li>
                                {usuarios.map((u,id)=>{
                                  return <li className="list-group-item mini-stat-img" key={id}>
                                            {u.usuario}<br/>
                                            <small>({u.perfil})</small>
                                         </li>
                                })}
                            </ul>
                          </CardBody>
                          }
                          <CardBody>
                              <Link to={"/curso/"+this.props.match.params.id} className="btn btn-primary waves-effect waves-light">Volver al curso</Link>
                          </CardBody>
                      </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Chat));
