import React, { Component } from 'react';
import {  Col,   Row,   Card,   CardBody,  CardImg, CardLink, Alert } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CONFIG from '../../CONFIG.json';
import { showLoader,getLoggedInUser } from '../../auxiliares/funciones';

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

// import images
import img2 from "../../assets/images/img-cursos.jpg";


class Cursos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Inicio", link : "/app" },
                { title : "Cursos", link : "/cursos" }
            ],
            cursos:[]
        }
    }

    componentDidMount(){
        this.props.setBreadcrumbItems("Cursos", this.state.breadcrumbItems);
        const ses = getLoggedInUser()
        fetch(CONFIG.api + "Cursos?action=list&token=" + ses.token)
          .then(res => res.json())
          .then((result) => {
            showLoader(false);
            if (result.Error === undefined) {
              this.setState({cursos:result})
            } else if (result.Caducada !== undefined) {
              this.props.history.push({
                pathname: '/login',
                search: '',
                state: { caducada: true, url: this.props.location.pathname} 
              });
            }else{
              this.setState({error:result.Error ,error_dlg:true})
            }
          })
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col md="12" lg="12" xl="12">
                    <Alert color="info"><i className="dripicons-information"></i> Aquí aparecen los cursos en los que actualmente estás matriculado.</Alert>
                    </Col>
                    {this.state.cursos.length === 0 &&
                      <Col md="12" lg="12" xl="12">
                      <Alert color="warning"><i className="dripicons-graduation"></i> Actualmente no estas matriculado en ningun curso.<br/>
                        Puede ser que tu curso aun no haya comenzado o que ya haya finalizado.
                      </Alert>
                      </Col>
                    }
                    {this.state.cursos.map((c,id)=>{
                    return <Col md="6" lg="6" xl="4" key={id}>
                              <Card>
                                  <CardImg top className="img-fluid" src={img2} alt="Imagen curso"/>
                                  <CardBody>
                                      <h4 className="card-title">{c.nombre}</h4>
                                  </CardBody>
                                  <ul className="list-group list-group-flush">
                                      <li className="list-group-item">Tutor: {c.tutor}</li>
                                      <li className="list-group-item">Teléfono Tutor: {c.tutortelf}</li>
                                      <li className="list-group-item">Inicio: {c.fechainicio}</li>
                                      <li className="list-group-item">Fin: {c.fechafin}</li>
                                  </ul>
                                  <CardBody>
                                      <CardLink href={'/curso/'+c.id} className="mr-1 btn btn-primary waves-effect waves-light">Acceder</CardLink>
                                      <CardLink href={"/mensajes/"+c.tutorid} style={{float:'right'}}>Contactar con el tutor</CardLink>
                                  </CardBody>
                              </Card>
                          </Col>
                    })}
                </Row>

                </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Cursos));
