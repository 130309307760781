import React, { Component } from 'react';
import {  Col,  Row,  Card,  CardBody,  CardHeader,  Alert,  FormGroup, Label, Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import CONFIG from '../../CONFIG.json';
import { showLoader, getLoggedInUser, isEmpty } from '../../auxiliares/funciones';
import { dateToString } from '../../auxiliares/dates';
import { withRouter } from 'react-router-dom';
import RTabla from '../../auxiliares/tabla';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WithContext as ReactTags } from 'react-tag-input';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../auxiliares/breadcrumb/actions";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Seguimiento extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems : [
              { title : "Inicio", link : "/app" },
              { title : "Seguimiento de alumnos", link : "/seguimiento" }
          ],
            error:'',
            error_dlg:false,
            msg:'',
            msg_dlg:false,
            grupo:'',
            grupos:[],
            filtro:{inicio:'', fin:'',grupos:[],usuario:''},
            listado:{
                      nombre:'Resultados',
                      lista:[],
                      buscable:true,
                      campos:[],
                      cabecera:[],
                      paginable:true,
                      scroll:true,
                      loader:true
                    }
        }
    }

    tagRemove = (i) => {
        const { filtro } = this.state;
        filtro.grupos = filtro.grupos.filter((tag, index) => index !== i)
        this.setState({filtro: filtro});
    }

    tagAdd = (tag) => {
      const { filtro } = this.state;
      filtro.grupos.push(tag)
      this.setState({filtro: filtro});
    }

    componentDidMount(){
        showLoader(true);
        this.props.setBreadcrumbItems('Seguimiento de alumnos', this.state.breadcrumbItems);
        showLoader(false);
    }

    buscarGrupos = (id,id2) =>{
      showLoader(true);
      const ses = getLoggedInUser()
      const filtro = this.state.filtro
      const grupos = filtro.grupos.map(g => g.text)
      fetch(CONFIG.api + "Informes?action=seguimiento&token="+ses.token+"&inicio="+dateToString(filtro.inicio)+"&fin="+dateToString(filtro.fin)+"&usuario="+filtro.usuario+"&grupos="+grupos.join(','))
        .then(res3 => res3.json())
        .then((result3) => {
          if (result3.Error === undefined) {
            if(isEmpty(result3)){
              showLoader(false);
              this.setState({msg:'No hay registros para mostrar' ,msg_dlg:true, listado:{lista:[]}})
            }else{
              this.setState({msg_dlg:false,listado: {lista:[]}})
              const listado = {
                        nombre:'Seguimiento',
                        lista:result3,
                        campos:['scormT','examenesGlobal','examenesGlobalesHechos','entidad','codaccion','curso','grupo','alumno','dni','tutor','horas','fechainicio','fechafin','numconexiones','tiempoconexiones','notageneral','notaponderada','cuestionario','diploma'],
                        cabecera:['Contenido Visitado','Nº Eval Globales','Nº Eval Globales Hechas','Entidad','Cod. Acción','Curso','Grupo','Alumno','DNI','Tutor','Horas Curso','Inicio Curso','Fin Curso','Nº Conexiones','Tiempo Conectado','Calif. General','Calif. Ponderada','Cuestionario','Diploma'],
                        paginable:true,
                        scroll:true,
                        loader:true
                      }
              this.setState({listado: listado})
            }
          }else if (result3.Caducada !== undefined) {
            this.props.history.push({
              pathname: '/login',
              search: '',
              state: { caducada: true, url: this.props.location.pathname}
            });
          }else{
            showLoader(false);
            this.setState({msg:result3.Error ,msg_dlg:true})
          }
      })
    }

    exportarGrupos = (id,id2) =>{
      const ses = getLoggedInUser()
      const filtro = this.state.filtro
      const grupos = filtro.grupos.map(g => g.text)
      window.open(CONFIG.api + "Informes?action=exportarSeguimiento&token="+ses.token+"&inicio="+dateToString(filtro.inicio)+"&fin="+dateToString(filtro.fin)+"&usuario="+filtro.usuario+"&grupos="+grupos.join(','), '_blank');
    }

    rellenaFiltro = (input) =>{
      const nombre = input.target.name
      const respuesta = input.target.value
      let res = this.state.filtro
      res[nombre] = respuesta
      this.setState({filtro:res})
    }

    rellenaFiltroFIni = (date) =>{
      let res = this.state.filtro
      res.inicio = date
      this.setState({filtro:res})
    }

    rellenaFiltroFFin = (date) =>{
      let res = this.state.filtro
      res.fin = date
      this.setState({filtro:res})
    }

    enviarMensaje = (obj) =>{
      window.open("/mensajes/"+obj.usuarioid,'_blank');
      // this.props.history.push("/mensajes/"+obj.usuarioid);
      // window.location.reload();
    }

    render() {
      const acciones = [{
        color:'primary',
        nombre:'Mensaje',
        icono:<i />,
        funcion: this.enviarMensaje,
        estilo: {},
        condition: null
      }]


        return (
            <React.Fragment>
                    <Row style={{minHeight: '400px'}}>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Seguimiento de alumnos</h4>
                                </CardHeader>
                                <CardBody className="row">
                                  <Col md="2" lg="2" xl="2" className="mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Usuario</Label>
                                        <Input className="form-control" onChange={(e)=>this.rellenaFiltro(e)} type="text" name="usuario"/>
                                    </FormGroup>
                                  </Col>
                                  <Col md="3" lg="3" xl="3" className="mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Inicio curso (Mayor o igual)</Label>
                                        <div>
                                        <InputGroup>
                                            <DatePicker dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                selected={this.state.filtro.inicio}
                                                onChange={this.rellenaFiltroFIni}
                                                name="inicio"
                                            />
                                            <InputGroupAddon addonType="append">
                                                        <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md="3" lg="3" xl="3" className="mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Fin Curso (Menor o igual)</Label>
                                        <div>
                                        <InputGroup>
                                            <DatePicker dateFormat="dd-MM-yyyy"
                                                className="form-control"
                                                selected={this.state.filtro.fin}
                                                onChange={this.rellenaFiltroFFin}
                                                name="fin"
                                            />
                                            <InputGroupAddon addonType="append">
                                                        <span className="input-group-text"><i className="mdi mdi-calendar"></i></span>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md="4" lg="4" xl="4" className="mt-4">
                                    <Button type="button" color="success" onClick={()=>{this.buscarGrupos()}} className="btn btn-success waves-effect waves-light">Buscar</Button>
                                    <Button type="button" color="warning" onClick={()=>{window.location.reload()}} className="btn btn-success waves-effect waves-light ml-4">Borrar</Button>
                                    <Button type="button" color="info" onClick={()=>{this.exportarGrupos()}} className="btn btn-success waves-effect waves-light ml-4">Exportar</Button>
                                  </Col>
                                  <Col md="6" lg="6" xl="6" className="mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Grupos</Label>
                                        <ReactTags
                                           placeholder="Introduce los grupos a buscar"
                                           tags={this.state.filtro.grupos}
                                           handleDelete={this.tagRemove}
                                           handleAddition={this.tagAdd}
                                           delimiters={delimiters}
                                           allowDragDrop={false}
                                           inline={false}
                                           inputFieldPosition="bottom"
                                           classNames={{ tagInputField: 'form-control' }}/>
                                    </FormGroup>
                                  </Col>
                                </CardBody>
                            </Card>
                            {!isEmpty(this.state.listado.lista) &&
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">{this.state.listado.nombre}</h4>
                                </CardHeader>
                                <CardBody row="true">
                                  <RTabla key={this.state.filtro} lista={this.state.listado.lista} buscable={this.state.listado.buscable}
                                  campos={this.state.listado.campos} cabecera={this.state.listado.cabecera} acciones={acciones}
                                  paginable={this.state.listado.paginable} scroll={this.state.listado.scroll} loader={this.state.listado.loader}/>
                                </CardBody>
                            </Card>
                            }
                            {this.state.msg_dlg &&
                                <Col md="12" lg="12" xl="12">
                                  <Alert color="warning"><i className="dripicons-warning"></i> {this.state.msg}</Alert>
                                </Col>
                            }
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { setBreadcrumbItems })(Seguimiento));
